import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchProperties } from '../../../api/propertiesApi';
import { UserContext } from '../../../context/UserContext';
import TaxNavbar from '../../components/TaxNavbar/TaxNavbar';
import styles from './LinkProperties.module.css';

const LinkProperties = () => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { data, temp_file_path } = location.state || {};
  const [properties, setProperties] = useState([]);
  const [linkedProperties, setLinkedProperties] = useState({});
  const [existingReservations, setExistingReservations] = useState([]);
  const [showOverridePrompt, setShowOverridePrompt] = useState(false);

  useEffect(() => {
    const loadProperties = async () => {
      const fetchedProperties = await fetchProperties();
      setProperties(fetchedProperties);
    };

    if (user) {
      loadProperties();
    }
  }, [user]);

  const handlePropertyChange = (airbnbProperty, event) => {
    setLinkedProperties({
      ...linkedProperties,
      [airbnbProperty]: event.target.value,
    });
  };

  const handleOverrideConfirm = async (override = false) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_CONNECT_CLEAN_API_URL}/api/link_properties`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          linked_properties: linkedProperties,
          temp_file_path: temp_file_path,
          override: override,
        }),
        credentials: 'include',
      });

      const result = await response.json();

      if (response.ok) {
        navigate('/upload-ki', { state: { data: result.tax_data, temp_file_path: temp_file_path } });
      } else {
        console.error('Error linking properties');
      }
    } catch (error) {
      console.error('Error linking properties:', error);
    }
  };

  const handleSubmit = async () => {
    console.log("Temp_file_path: ", temp_file_path)
    try {
      const response = await fetch(`${process.env.REACT_APP_CONNECT_CLEAN_API_URL}/api/link_properties`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          linked_properties: linkedProperties,
          temp_file_path: temp_file_path,
        }),
        credentials: 'include',
      });

      const result = await response.json();

      if (response.ok) {
        if (result.existing_reservations) {
          setExistingReservations(result.existing_reservations);
          setShowOverridePrompt(true);
        } else {
          navigate('/taxes/upload-ki', { state: { data: result.tax_data, temp_file_path: temp_file_path } });
        }
      } else {
        console.error('Error linking properties');
      }
    } catch (error) {
      console.error('Error linking properties:', error);
    }
  };

  return (
    <div>
      <TaxNavbar />
      <div className={styles.container}>
        <h1>Link Properties</h1>
        {data && Object.keys(data.tax_data_per_property)
          .map((airbnbProperty, index) => (
            <div key={index} className={styles.propertyContainer}>
              <h2>{airbnbProperty}</h2>
              <select
                value={linkedProperties[airbnbProperty] || ''}
                onChange={(e) => handlePropertyChange(airbnbProperty, e)}
                className={styles.propertySelect}
              >
                <option value="">Select Property</option>
                {properties.map((property) => (
                  <option key={property.id} value={property.id}>
                    {property.title}
                  </option>
                ))}
              </select>
            </div>
          ))}
        <button onClick={handleSubmit} className={styles.submitButton}>Submit</button>
        {showOverridePrompt && (
          <div className={styles.overridePrompt}>
            <h2>Existing Reservations Detected</h2>
            <p>There are existing reservations for the selected properties and earnings year. Do you want to override them?</p>
            <button onClick={() => handleOverrideConfirm(true)} className={styles.confirmButton}>Yes, Override</button>
            <button onClick={() => setShowOverridePrompt(false)} className={styles.cancelButton}>Cancel</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkProperties;
