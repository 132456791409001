import React, { useContext, useState } from 'react';
import { WizardContext, FormDataContext } from '../PropertyFormWizard';
import { useNavigate } from 'react-router-dom';
import { createProperty } from 'api/propertiesApi';
import Button from 'components/UI/Button/Button';
import Alert from 'components/UI/Alert/Alert';

export default function WizardNavigation() {
  const { 
    goToNextStep, 
    goToPreviousStep, 
    skipStep, 
    isLastStep, 
    isFirstStep, 
    currentStepIndex,
    steps
  } = useContext(WizardContext);
  
  const { formData, updateFormData } = useContext(FormDataContext);
  const [showValidationError, setShowValidationError] = useState(false);
  const navigate = useNavigate();
  
  const currentStep = steps[currentStepIndex];
  
  const handleCreateProperty = async () => {
    try {
      // Prepare data for API
      const propertyData = {
        property: {
          title: formData.title,
          address: formData.address,
          square_meters: formData.square_meters,
          number_rooms: formData.number_rooms,
          number_bathrooms: formData.number_bathrooms,
          has_cleaning_supplies: formData.has_cleaning_supplies,
          description: formData.description,
          default_job_price: formData.default_job_price,
          default_job_price_currency: formData.default_job_price_currency,
          default_cleaning_from: formData.default_cleaning_from,
          default_cleaning_until: formData.default_cleaning_until,
          photo: formData.photo,
        },
        managers: formData.managers,
        cleaners: formData.cleaners,
      };
      
      // Add airbnb_import if it exists
      if (formData.airbnb_import) {
        propertyData.airbnb_import = formData.airbnb_import;
      }
      
      await createProperty(propertyData);
      navigate('/properties'); // Redirect to the properties index after successful creation
    } catch (error) {
      console.error('Failed to create property:', error);
      alert('There was an error creating the property. Please try again.');
    }
  };
  
  const handleNextClick = () => {
    if (isLastStep) {
      handleCreateProperty();
      return;
    }
    
    if (currentStep.id === 'basics') {
      if (!formData.title.trim() || !formData.address.trim()) {
        setShowValidationError(true);
        updateFormData({
          ...formData,
          _validateBasics: true
        });
        return;
      }
    } else if (currentStep.id === 'budget') {
      if (!formData.default_job_price) {
        setShowValidationError(true);
        updateFormData({
          ...formData,
          _validateBudget: true
        });
        return;
      }
    }
    
    setShowValidationError(false);
    goToNextStep();
  };

  const handleBackClick = () => {
    setShowValidationError(false);
    goToPreviousStep();
  };
  
  return (
    <div className="flex flex-col">
      <div className="flex justify-between mt-6">
        <Button 
          variant="outline"
          onClick={handleBackClick}
          disabled={isFirstStep}
        >
          Back
        </Button>
        
        <div className="flex items-center">
          {!currentStep.required && (
            <Button 
              variant="link"
              className="mr-3"
              onClick={skipStep}
            >
              Skip this step
            </Button>
          )}
          
          <Button 
            variant='primary'
            onClick={handleNextClick}
          >
            {isLastStep ? 'Create Property' : 'Next'}
          </Button>
        </div>
      </div>
      
      {showValidationError && (
        <div className="mt-4">
          <Alert variant="error">
            Please fill in all required fields before proceeding.
          </Alert>
        </div>
      )}
    </div>
  );
}
