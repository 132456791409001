import React from 'react';

/**
 * FormInput component for text inputs with labels, validation, and help text
 * @param {Object} props - Component props
 * @param {string} props.label - Input label
 * @param {string} props.id - Input ID
 * @param {string} props.name - Input name
 * @param {string} props.value - Input value
 * @param {Function} props.onChange - Function called when input changes
 * @param {Function} props.onBlur - Function called when input loses focus
 * @param {string} props.error - Error message
 * @param {string} props.helpText - Help text
 * @param {string} props.type - Input type
 * @param {boolean} props.required - Whether the input is required
 * @param {string} props.className - Additional CSS classes
 */
const FormInput = ({
  label,
  id,
  name,
  value,
  onChange,
  onBlur,
  error,
  helpText,
  type = 'text',
  required = false,
  className = '',
  ...props
}) => {
  return (
    <div className={className}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        className={`w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary ${
          error ? 'border-red-500' : 'border-gray-300'
        }`}
        {...props}
      />
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
      {helpText && (
        <p className="mt-1 text-sm text-gray-500">{helpText}</p>
      )}
    </div>
  );
};

export default FormInput;