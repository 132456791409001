import React, { useContext, useState, useEffect } from 'react';
import { FormDataContext, WizardContext } from '../PropertyFormWizard';
import MapComponent from 'cleaningApp/components/MapBox/MapComponent';
import AddressAutoComplete from 'cleaningApp/components/MapBox/AddressAutoComplete';
import FormInput from 'components/UI/Form/FormInput';
import FileUpload from 'components/UI/Form/FileUpload';

export default function PropertyBasicsStep() {
  const { formData, updateFormData } = useContext(FormDataContext);
  const { goToNextStep } = useContext(WizardContext);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  
  // Validate form when component mounts and when formData changes
  useEffect(() => {
    validateForm();
    
    // Check if validation was triggered from WizardNavigation
    if (formData._validateBasics) {
      setTouched({ title: true, address: true });
      // Remove the trigger flag
      updateFormData({ _validateBasics: undefined });
    }
  }, [formData]);
  
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.title.trim()) {
      newErrors.title = 'Property name is required';
    }
    
    if (!formData.address.trim()) {
      newErrors.address = 'Property address is required';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });
    
    // Mark field as touched
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
  };
  
  const handleAddressChange = (newAddress) => {
    updateFormData({ address: newAddress });
    
    // Mark address field as touched
    setTouched(prev => ({
      ...prev,
      address: true
    }));
  };
  
  const handleFileChange = (file) => {
    if (file) {
      updateFormData({ photo: file });
      setPhotoPreview(URL.createObjectURL(file));
    }
  };
  
  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
  };
  
  return (
    <div className="space-y-6">
      <div className="mb-4">
        <FormInput
          label="Property Name *"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          onBlur={handleBlur}
          error={touched.title && errors.title}
          helpText="Give your property a clear, descriptive name."
          required
        />
      </div>
      
      <div className="mb-4">
        <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">
          Property Address *
        </label>
        <AddressAutoComplete
          initialAddress={formData.address}
          onAddressSelect={handleAddressChange}
        />
        {touched.address && errors.address && (
          <p className="mt-1 text-sm text-red-600">{errors.address}</p>
        )}
        <p className="mt-1 text-sm text-gray-500">
          Enter the complete address of your property.
        </p>
      </div>
      
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Property Location
        </label>
        <div className="h-[300px] rounded-md overflow-hidden border border-gray-300 shadow-sm">
          <MapComponent 
            address={formData.address}
            onAddressChange={handleAddressChange}
          />
        </div>
        <p className="mt-1 text-sm text-gray-500">
          Confirm the location on the map or adjust it by clicking on the correct location.
        </p>
      </div>
      
      <div className="mb-4">
        <FileUpload
          label="Property Photo (Optional)"
          id="photo"
          name="photo"
          accept="image/*"
          onChange={handleFileChange}
          preview={photoPreview}
          previewAlt="Property preview"
        />
      </div>
    </div>
  );
} 