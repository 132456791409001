import React, { useContext } from 'react';
import { WizardContext } from '../PropertyFormWizard';
import Button from 'components/UI/Button/Button';

/**
 * ReviewCard component for displaying a section of review information
 * with a title and an edit button that navigates to a specific wizard step
 * 
 * @param {Object} props
 * @param {string} props.title - The title of the review card
 * @param {number} props.stepNumber - The step number to navigate to when edit is clicked
 * @param {React.ReactNode} props.children - The content to display in the card body
 * @param {string} props.className - Additional CSS classes
 */
const ReviewCard = ({ title, stepNumber, children, className = '' }) => {
  const { goToStep } = useContext(WizardContext);
  
  return (
    <div className={`bg-white rounded-lg shadow-md border border-gray-200 mb-4 ${className}`}>
      <div className="flex justify-between items-center px-4 py-3 border-b border-gray-200">
        <h5 className="font-medium text-gray-800 m-0">{title}</h5>
        <Button 
          variant="link" 
          size="sm"
          onClick={() => goToStep(stepNumber)}
        >
          Edit
        </Button>
      </div>
      <div className="p-4">
        {children}
      </div>
    </div>
  );
};

export default ReviewCard; 