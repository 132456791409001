import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext'; // Import UserContext
import Login from '../../../components/Login/Login'; // Import the Login component
import styles from './TaxNavbar.module.css'; // Create and import your CSS module for styling

function TaxNavbar() {
  const { user, logout } = useContext(UserContext); // Use UserContext
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/taxes/login'); // Update to use navigate instead of returning component
  };

  const handleLogout = async () => {
    await logout(); // Use the logout function from UserContext
    navigate('/taxes'); // Updated from /tax-home
  };

  return (
    <div className={styles.navbar}>
      {user ? (
        <div className={styles.userInfo}>
          <span>Logged in as: {user.first_name}</span>
          <button onClick={handleLogout} className={styles.logoutButton}>Logout</button>
        </div>
      ) : (
        <div className={styles.userInfo}>
          <span>Not logged in</span>
          <button onClick={handleLoginClick} className={styles.loginButton}>Login</button>
        </div>
      )}
    </div>
  );
}

export default TaxNavbar;
