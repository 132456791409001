import React, { useEffect, useState } from 'react';
import styles from './UserProfile.module.css';
import { fetchUserProfile, updateUserProfile } from 'api/usersApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

export default function UserProfile() {
  const [userProfile, setUserProfile] = useState(null);
  const [formData, setFormData] = useState({ first_name: '', last_name: '', password: '', new_password: '', confirm_new_password: '' });
  const [isEditing, setIsEditing] = useState({ first_name: false, last_name: false, password: false });
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    async function getUserProfile() {
      const profileObject = await fetchUserProfile();
      setUserProfile(profileObject.user);
      setFormData({
        first_name: profileObject.user.first_name,
        last_name: profileObject.user.last_name,
        password: '',
        new_password: '',
        confirm_new_password: '',
      });
    }
    getUserProfile();
  }, []);

  useEffect(() => {
    const handleClickOutside = () => {
      setErrorMessage('');
    };

    // Add event listener to the document
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async (field) => {
    try {
      if (field === 'password' && formData.new_password !== formData.confirm_new_password) {
        setErrorMessage('Passwords do not match!');
        return;
      }
      await updateUserProfile({ [field]: formData[field] });
      setUserProfile((prevProfile) => ({
        ...prevProfile,
        [field]: formData[field],
      }));
      setIsEditing((prevEditing) => ({ ...prevEditing, [field]: false }));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Failed to update profile: ' + error.message);
    }
  };

  const handleSavePassword = async () => {
    try {
      if (formData.new_password !== formData.confirm_new_password) {
        setErrorMessage('New passwords do not match!');
        return;
      }
      await updateUserProfile({ 
        password: formData.password, 
        new_password: formData.new_password, 
        confirm_new_password: formData.confirm_new_password 
      });
      setIsEditing((prevEditing) => ({ ...prevEditing, password: false }));
      setErrorMessage('');
      alert('Password updated successfully!');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('Failed to update password: ' + error.message);
      }
    }
  };

  const handleCancelPasswordChange = () => {
    setFormData({ password: '', new_password: '', confirm_new_password: '' });
    setIsEditing((prevEditing) => ({ ...prevEditing, password: false }));
  };

  if (!userProfile) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`container-fluid p-0 ${styles.userProfile}`}>
      <div className={styles.profileHeader}>
        <h1>User Profile</h1>
      </div>
      <div className={styles.profileContent}>
        <div className={styles.form}>
            <div className={styles.formGroup}>
                <label>Email</label>
                <div className={styles.fieldDisplay}>
                    <span>{userProfile.email}</span>
                </div>
            </div>
          <div className={styles.formGroup}>
            <label htmlFor="first_name">First Name</label>
            {isEditing.first_name ? (
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                className="form-control"
                onBlur={() => handleSave('first_name')}
              />
            ) : (
              <div className={styles.fieldDisplay}>
                <span>{userProfile.first_name}</span>
                <button onClick={() => setIsEditing({ ...isEditing, first_name: true })} className={styles.iconButton}>
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              </div>
            )}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="last_name">Last Name</label>
            {isEditing.last_name ? (
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                className="form-control"
                onBlur={() => handleSave('last_name')}
              />
            ) : (
              <div className={styles.fieldDisplay}>
                <span>{userProfile.last_name}</span>
                <button onClick={() => setIsEditing({ ...isEditing, last_name: true })} className={styles.iconButton}>
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              </div>
            )}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="password">Change Password</label>
            {isEditing.password ? (
              <div className={styles.passwordChangeContainer}>
                <div className={styles.formGroup}>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="form-control mb-2"
                    placeholder="Current Password"
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="password"
                    id="new_password"
                    name="new_password"
                    value={formData.new_password}
                    onChange={handleInputChange}
                    className="form-control mb-2"
                    placeholder="New Password"
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="password"
                    id="confirm_new_password"
                    name="confirm_new_password"
                    value={formData.confirm_new_password}
                    onChange={handleInputChange}
                    className="form-control mb-2"
                    placeholder="Confirm New Password"
                  />
                </div>
                <div className={styles.buttonGroup}>
                  <button onClick={handleSavePassword} className="btn btn-primary">
                    Save Password
                  </button>
                  <button onClick={handleCancelPasswordChange} className="btn btn-secondary">
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <button onClick={() => setIsEditing({ ...isEditing, password: true })} className={styles.iconButton}>
                <FontAwesomeIcon icon={faEdit} />
              </button>
            )}
          </div>
        </div>
      </div>
      {errorMessage && (
        <div className={`alert alert-danger ${styles.centeredErrorMessage}`}>{errorMessage}</div>
      )}
    </div>
  );
}
