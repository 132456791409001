import React from 'react';

/**
 * TimeRangeInput component for selecting a time range with start and end times
 * @param {Object} props - Component props
 * @param {string} props.label - Input label
 * @param {string} props.startId - ID for the start time input
 * @param {string} props.startName - Name for the start time input
 * @param {string} props.startValue - Value for the start time input
 * @param {string} props.endId - ID for the end time input
 * @param {string} props.endName - Name for the end time input
 * @param {string} props.endValue - Value for the end time input
 * @param {string} props.startLabel - Label for the start time input
 * @param {string} props.endLabel - Label for the end time input
 * @param {Function} props.onChange - Function called when input changes
 * @param {string} props.error - Error message
 * @param {string} props.helpText - Help text
 * @param {boolean} props.required - Whether the input is required
 * @param {string} props.className - Additional CSS classes
 */
const TimeRangeInput = ({
  label,
  startId,
  startName,
  startValue,
  endId,
  endName,
  endValue,
  startLabel = 'From',
  endLabel = 'Until',
  onChange,
  error,
  helpText,
  required = false,
  className = '',
}) => {
  return (
    <div className={className}>
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {label}
        </label>
      )}
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label htmlFor={startId} className="block text-sm font-medium text-gray-700 mb-1">
            {startLabel}
          </label>
          <input
            type="time"
            id={startId}
            name={startName}
            value={startValue || ''}
            onChange={onChange}
            required={required}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
          />
        </div>
        
        <div>
          <label htmlFor={endId} className="block text-sm font-medium text-gray-700 mb-1">
            {endLabel}
          </label>
          <input
            type="time"
            id={endId}
            name={endName}
            value={endValue || ''}
            onChange={onChange}
            required={required}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
          />
        </div>
      </div>
      
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
      {helpText && (
        <p className="mt-1 text-sm text-gray-500">{helpText}</p>
      )}
    </div>
  );
};

export default TimeRangeInput; 