import React from "react";
import { useNavigate } from 'react-router-dom';
import styles from './TaxHome.module.css';

export default function Tax() {

  const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate('/taxes/login');  // Navigate to the login page
    };

    const handleGuestClick = () => {
        navigate('/taxes/upload-earnings'); // Updated from /upload-earnings
    };

  return (
      <div className={styles.container}>
          <header className={styles.header}>
              <h1>ConnectClean</h1>
              <h2>Manage your Taxes</h2>
          </header>

          <div className={styles.content}>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tristique orci orci, at luctus velit dignissim in. Maecenas elit arcu, vestibulum quis rutrum in, cursus pharetra odio. Aenean sit amet porta orci. Pellentesque aliquet felis a fermentum scelerisque.</p>

              <div className={styles.action}>
                  <h3>Already have a ConnectClean Account?</h3>
                  <button className={styles.button} onClick={handleLoginClick}>Login</button>
              </div>

              <div className={styles.action}>
                  <h3>Just give me my tax declaration as a guest</h3>
                  <button className={styles.button} onClick={handleGuestClick}>Provide Tax Declaration as Guest</button>
              </div>
          </div>
      </div>
  );
}
