import React from 'react';

/**
 * ProgressStep component for displaying a single step in a multi-step process
 * @param {Object} props - Component props
 * @param {string} props.title - Step title
 * @param {number} props.number - Step number
 * @param {boolean} props.isActive - Whether the step is currently active
 * @param {boolean} props.isCompleted - Whether the step is completed
 * @param {boolean} props.isAccessible - Whether the step can be clicked
 * @param {Function} props.onClick - Function called when step is clicked
 * @param {string} props.className - Additional CSS classes
 */
const ProgressStep = ({
  title,
  number,
  isActive = false,
  isCompleted = false,
  isAccessible = true,
  onClick,
  className = '',
}) => {
  const handleClick = () => {
    if (isAccessible && onClick) {
      onClick();
    }
  };

  return (
    <div 
      className={`flex flex-col items-center ${className}`}
      onClick={handleClick}
      style={{ cursor: isAccessible ? 'pointer' : 'not-allowed' }}
    >
      <div 
        className={`
          flex items-center justify-center
          w-8 h-8 rounded-full mb-1
          text-sm font-medium
          ${isActive ? 'bg-primary text-white' : 
            isCompleted ? 'bg-primary-lighter text-white' : 
            'bg-gray-200 text-gray-700'}
          ${!isAccessible ? 'opacity-50' : ''}
        `}
      >
        {isCompleted ? '✓' : number}
      </div>
      <div className={`
        text-xs font-medium
        ${isActive ? 'text-primary' : 'text-gray-700'}
        ${!isAccessible ? 'opacity-50' : ''}
      `}>
        {title}
      </div>
    </div>
  );
};

export default ProgressStep;