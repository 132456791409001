import React, { useState } from 'react';

const AddressAutoComplete = ({ onAddressSelect, initialAddress, className = '' }) => {
  const [addressInput, setAddressInput] = useState(initialAddress || '');
  const [addressSuggestions, setAddressSuggestions] = useState([]);

  const handleAddressInputChange = (e) => {
    const value = e.target.value;
    setAddressInput(value);
    
    // Only fetch suggestions if the input is 3 or more characters
    if (value.length >= 3) {
      fetchAddressSuggestions(value);
    } else {
      setAddressSuggestions([]);
    }
  };

  const fetchAddressSuggestions = (query) => {
    // Use the Mapbox geocoding API to fetch address suggestions
    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}&autocomplete=true`)
      .then(response => response.json())
      .then(data => {
        setAddressSuggestions(data.features.map(feature => ({
          text: feature.place_name,
          center: feature.center
        })));
      })
      .catch(error => console.error('Error fetching address suggestions:', error));
  };

  const handleSuggestionSelect = (suggestion) => {
    setAddressInput(suggestion.text);
    setAddressSuggestions([]);
    onAddressSelect(suggestion.text);
  };

  return (
    <div className={`relative ${className}`}>
      <input
        type="text"
        value={addressInput}
        onChange={handleAddressInputChange}
        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-primary-light focus:border-primary"
        placeholder="Enter address"
      />
      {addressSuggestions.length > 0 && (
        <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {addressSuggestions.map((suggestion, index) => (
            <li 
              key={index} 
              className="px-4 py-2 hover:bg-primary-lightest cursor-pointer border-b border-gray-100 last:border-b-0"
              onClick={() => handleSuggestionSelect(suggestion)}
            >
              {suggestion.text}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AddressAutoComplete;