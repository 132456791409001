import React from 'react';

/**
 * Tabs component for switching between different views or content sections
 * @param {Object} props - Component props
 * @param {Array} props.tabs - Array of tab objects [{id: 'tab1', label: 'Tab 1'}, ...]
 * @param {string} props.activeTab - ID of the currently active tab
 * @param {Function} props.onChange - Function called when tab changes
 * @param {string} props.className - Additional CSS classes
 */
const Tabs = ({ 
  tabs, 
  activeTab, 
  onChange, 
  className = '',
  size = 'md',
  ...props 
}) => {
  // Define size styles
  const sizes = {
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg',
  };

  return (
    <div 
      className={`inline-flex rounded-md border border-gray-300 ${sizes[size]} ${className}`}
      role="tablist"
      {...props}
    >
      {tabs.map((tab) => (
        <button
          key={tab.id}
          type="button"
          role="tab"
          aria-selected={activeTab === tab.id}
          onClick={() => onChange(tab.id)}
          className={`
            px-4 py-2 transition-colors duration-200 first:rounded-l-md last:rounded-r-md
            ${activeTab === tab.id 
              ? 'bg-primary-light text-white font-medium' 
              : 'bg-white text-gray-800 hover:bg-primary-lightest'}
            ${tab.id !== tabs[0].id ? 'border-l border-gray-300' : ''}
          `}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default Tabs; 