import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext'; // Import UserContext
import TaxNavbar from '../../components/TaxNavbar/TaxNavbar'; // Import the TaxNavbar component
import styles from './UploadEarnings.module.css';

function UploadEarnings() {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const { user } = useContext(UserContext); // Use UserContext
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage('Please select a file first.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`${process.env.REACT_APP_CONNECT_CLEAN_API_URL}/api/process_excel`, {
        method: 'POST',
        body: formData,
        credentials: 'include',
      });

      const result = await response.json();
      if (response.ok) {
        setMessage(result.message || 'File uploaded successfully!');

        if (user) {
          navigate('/taxes/link-properties', { state: { data: result.data, temp_file_path: result.temp_file_path } });
        } else {
          navigate('/taxes/upload-ki', { state: { data: result.data, temp_file_path: result.temp_file_path } });
        }
      } else {
        setMessage(result.error || 'Error uploading file');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Error uploading file');
    }
  };

  return (
    <div>
      <TaxNavbar /> {/* Include the TaxNavbar component */}
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>Upload Earnings</h1>
          <p className={styles.description}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tristique orci orci, at luctus velit dignissim in.
            Maecenas elit arcu, vestibulum quis rutrum in, cursus pharetra odio. Aenean sit amet porta orci.
            Pellentesque aliquet felis a fermentum scelerisque.
          </p>
          <input type="file" onChange={handleFileChange} className={styles.fileInput} />
          <button onClick={handleUpload} className={styles.uploadButton}>Input Earnings</button>
          {message && <p className={styles.message}>{message}</p>}
        </div>
      </div>
    </div>
  );
}

export default UploadEarnings;
