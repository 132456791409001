import React from 'react';

/**
 * ProgressBar component for displaying progress in a multi-step process
 * @param {Object} props - Component props
 * @param {number} props.progress - Progress percentage (0-100)
 * @param {number} props.current - Current step value
 * @param {number} props.min - Minimum step value
 * @param {number} props.max - Maximum step value
 * @param {string} props.className - Additional CSS classes
 */
const ProgressBar = ({
  progress,
  current,
  min = 0,
  max = 100,
  className = '',
}) => {
  return (
    <div className={`h-2 bg-gray-200 rounded-full overflow-hidden ${className}`}>
      <div 
        className="h-full bg-primary transition-all duration-300 ease-in-out"
        style={{ width: `${progress}%` }}
        role="progressbar"
        aria-valuenow={current}
        aria-valuemin={min}
        aria-valuemax={max}
      ></div>
    </div>
  );
};

export default ProgressBar;