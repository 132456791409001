import React from 'react';
import styles from './JobForm.module.css';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';
import Button from '../../../components/UI/Button/Button';

export default function JobFormFields({ formData, setFormData, properties, cleaners, selectedProperty, handleInputChange, handlePropertySelect, handleSubmit, user, formMode }) {
  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.formGroup}>
        <label htmlFor="property_id" className={styles.formLabel}>Select Property</label>
        <select
          id="property_id"
          name="property_id"
          value={formData.property_id}
          onChange={handlePropertySelect}
          className={styles.formSelect}
        >
          <option value="">Select Property</option>
          {properties.map(property => (
            <option key={property.id} value={property.id}>{property.title}</option>
          ))}
        </select>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="date_of_job" className={styles.formLabel}>Date of Job</label>
        <input
          type="date"
          id="date_of_job"
          name="date_of_job"
          value={formData.date_of_job || new Date().toISOString().split('T')[0]}
          onChange={handleInputChange}
          className={styles.formControl}
        />
      </div>

      {(user?.concierge ?? false) && (
        <div className={styles.formGroup}>
          <label htmlFor="revenue" className={styles.formLabel}>Revenue</label>
          <div className={styles.currencyInput}>
            <span className={styles.currencySymbol}>€</span>
            <input
              type="number"
              id="revenue"
              name="revenue"
              value={formData.revenue}
              onChange={handleInputChange}
              className={`${styles.formControl} ${styles.currencyControl}`}
              placeholder="Revenue"
            />
          </div>
        </div>
      )}

      <div className={styles.formGroup}>
        <label htmlFor="price" className={styles.formLabel}>
          Price {formData.price_currency ? `(${formData.price_currency})` : ''}
        </label>
        <input
          type="number"
          id="price"
          name="price"
          value={formData.price}
          onChange={handleInputChange}
          className={styles.formControl}
          placeholder="Job Price"
          min="0.01"
          step="0.01"
          required
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="cleaning_from" className={styles.formLabel}>Cleaning From</label>
        <input
          type="time"
          id="cleaning_from"
          name="cleaning_from"
          value={formData.cleaning_from}
          onChange={handleInputChange}
          className={styles.formControl}
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="cleaning_until" className={styles.formLabel}>Cleaning Until</label>
        <input
          type="time"
          id="cleaning_until"
          name="cleaning_until"
          value={formData.cleaning_until}
          onChange={handleInputChange}
          className={styles.formControl}
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="description" className={styles.formLabel}>Description</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          className={styles.formControl}
          rows="5"
          placeholder="Description"
        />
      </div>
      
      {cleaners.length > 0 ? (
        <div className={styles.cleanersList}>
          <h3 className={styles.formLabel}>
            Cleaners Eligible for this Job 
            <FaInfoCircle className={styles.infoIcon} title="These cleaners will be notified about the job and can choose to accept it. Once accepted, the job will be assigned to that cleaner and hidden from others." />
          </h3>
          <ul>
            {cleaners.map(cleaner => (
              <li key={cleaner.id}>
                {cleaner.first_name} - {cleaner.email}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className={styles.noCleanersMessage}>
          <h3 className={styles.noCleanersTitle}>No Cleaners Assigned</h3>
          <p className={styles.noCleanersText}>
            There are currently no cleaners assigned to this property. Please add cleaners in the "My Team" section of the property details.
          </p>
          {selectedProperty && (
            <Link 
              to={`/properties/${selectedProperty.id}`}
              className={styles.propertyLink}
            >
              Go to Property Details
            </Link>
          )}
        </div>
      )}

      <Button 
        type="submit" 
        variant={formMode === 'edit' ? 'secondary' : 'primary'}
        disabled={cleaners.length === 0}
        data-tip={cleaners.length === 0 ? "Add cleaners to the property first" : ""}
        className={styles.submitButton}
      >
        {formMode === 'edit' ? 'Edit Job' : 'Create Job'}
      </Button>
      <Tooltip place="top" type="dark" effect="solid" />
    </form>
  );
}
