import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setTokens, login, handleOAuthCallback } from 'api/authUtils';
import { UserContext } from 'context/UserContext';
import Button from '../UI/Button/Button';

function Login({ navigatePath }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { fetchAndSetUser } = useContext(UserContext);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const data = await login(email, password);
      setTokens(data.access_token, data.refresh_token);
      await fetchAndSetUser();
      navigate(navigatePath);
    } catch (error) {
      setError(error.error || 'Network error');
    }
  };

  const handleRegisterRedirect = () => {
    if (navigatePath === "/taxes") {
      navigate('taxes/register', { state: { navigatePath } });
    } else {
      navigate('/register', { state: { navigatePath } });
    }
  };

  const handleGoogleLogin = () => {
    const frontendUrl = process.env.REACT_APP_FRONTEND_URL || 'http://localhost:3001';
    const redirectUrl = encodeURIComponent(`${frontendUrl}${navigatePath}`);
    const apiUrl = process.env.REACT_APP_CONNECT_CLEAN_API_URL.replace(/\/$/, ''); // Remove trailing slash if present
    window.location.href = `${apiUrl}/users/auth/google_oauth2?redirect_url=${redirectUrl}`;
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('access_token')) {
      handleOAuthCallback(params)
        .then(() => {
          fetchAndSetUser();
          navigate(navigatePath);
        })
        .catch(error => {
          setError('Failed to authenticate with Google');
          console.error(error);
        });
    }
  }, [navigate, fetchAndSetUser, navigatePath]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-primary-lightest to-secondary-lightest p-4">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
        <h1 className="text-2xl font-semibold text-center text-gray-800 mb-6">Login</h1>
        
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Email:</label>
            <input 
              type="email" 
              value={email} 
              onChange={e => setEmail(e.target.value)} 
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
              required
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Password:</label>
            <input 
              type="password" 
              value={password} 
              onChange={e => setPassword(e.target.value)} 
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
              required
            />
          </div>
          
          <Button type="submit" variant="primary" className="w-full">
            Login
          </Button>
          
          <Button 
            type="button" 
            onClick={handleGoogleLogin} 
            variant="outline"
            className="w-full flex items-center justify-center gap-2"
          >
            <svg width="20" height="20" viewBox="0 0 48 48">
              <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
              <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
              <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
              <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </svg>
            Sign in with Google
          </Button>
        </form>
        
        {error && <p className="text-danger font-medium text-sm mt-4">{error}</p>}
        
        <p className="text-center text-sm text-gray-600 mt-6">
          Don't have an account? 
          <button 
            onClick={handleRegisterRedirect} 
            className="text-primary hover:text-primary-dark ml-1 underline focus:outline-none"
          >
            Register here
          </button>
        </p>
      </div>
    </div>
  );
}

export default Login;
