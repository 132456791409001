import React from 'react';

/**
 * RadioGroup component for selecting a single option from multiple choices
 * @param {Object} props - Component props
 * @param {Array} props.options - Array of option objects [{id: 'option1', label: 'Option 1'}, ...]
 * @param {string} props.name - Name attribute for the radio inputs
 * @param {string} props.selectedValue - Currently selected value
 * @param {Function} props.onChange - Function called when selection changes
 * @param {string} props.className - Additional CSS classes
 * @param {string} props.layout - Layout direction ('horizontal' or 'vertical')
 */
const RadioGroup = ({
  options,
  name,
  selectedValue,
  onChange,
  className = '',
  layout = 'horizontal',
  ...props
}) => {
  // Define layout styles
  const layouts = {
    horizontal: 'flex space-x-6',
    vertical: 'flex flex-col space-y-3',
  };

  return (
    <div 
      className={`${layouts[layout]} ${className}`}
      role="radiogroup"
      {...props}
    >
      {options.map((option) => (
        <div key={option.id} className="flex items-center">
          <div className="relative flex items-center">
            <input
              className="appearance-none w-4 h-4 rounded-full border border-gray-300 checked:border-primary checked:border-2 focus:outline-none focus:ring-2 focus:ring-primary-light"
              type="radio"
              name={name}
              id={option.id}
              value={option.id}
              checked={selectedValue === option.id}
              onChange={() => onChange(option.id)}
            />
            {selectedValue === option.id && (
              <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                <div className="w-2 h-2 rounded-full bg-primary"></div>
              </div>
            )}
          </div>
          <label className="ml-2 text-body" htmlFor={option.id}>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;