import React, { useContext, useEffect } from 'react';
import { FormDataContext } from '../PropertyFormWizard';
// Import the reusable components
import FormInput from 'components/UI/Form/FormInput';
import FormSelect from 'components/UI/Form/FormSelect';
import FormCheckbox from 'components/UI/Form/FormCheckbox';
import Alert from 'components/UI/Alert/Alert';

export default function CalendarStep() {
  const { formData, updateFormData } = useContext(FormDataContext);
  
  // Initialize airbnb_import if it doesn't exist
  useEffect(() => {
    if (!formData.airbnb_import) {
      updateFormData({
        airbnb_import: {
          ical_url: formData.ical_url || '',
          import_weeks: formData.import_weeks || '4',
          auto_post: formData.auto_post || false,
          post_all: false
        }
      });
    }
  }, []);
  
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Update the airbnb_import object
    updateFormData({
      airbnb_import: {
        ...formData.airbnb_import,
        [name]: type === 'checkbox' ? checked : value
      }
    });
  };
  
  return (
    <div className="space-y-6">
      <Alert variant="primary" className="mb-6">
        <p>
          Connect your Airbnb calendar to automatically create cleaning jobs when guests check out.
        </p>
      </Alert>
      
      <FormInput
        label="Airbnb iCal URL"
        id="ical_url"
        name="ical_url"
        value={formData.airbnb_import?.ical_url || ''}
        onChange={handleInputChange}
        placeholder="https://www.airbnb.com/calendar/ical/..."
        helpText="Copy and paste your Airbnb iCal link here. You can find this in your Airbnb hosting dashboard."
        className="mb-6"
      />
      
      <FormSelect
        label="Import Calendar For"
        id="import_weeks"
        name="import_weeks"
        value={formData.airbnb_import?.import_weeks || '4'}
        onChange={handleInputChange}
        options={[
          { value: '1', label: '1 Week' },
          { value: '2', label: '2 Weeks' },
          { value: '4', label: '4 Weeks' },
          { value: '8', label: '8 Weeks' },
        ]}
        className="mb-6"
      />
      
      <FormCheckbox
        id="auto_post"
        name="auto_post"
        checked={formData.airbnb_import?.auto_post || false}
        onChange={handleInputChange}
        label="Automatically post jobs to cleaners"
        helpText="When enabled, cleaning jobs will be automatically created and assigned to available cleaners."
      />
    </div>
  );
}
