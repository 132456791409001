import React, { useState, useEffect, useContext } from 'react';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import { fetchJobs, deleteJob, acceptJob, reopenJob } from '../../../api/jobsApi';
import { fetchProperties } from 'api/propertiesApi';
import styles from './Calendar.module.css';
import JobDetails from './JobDetails';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { FaFilter, FaTrash, FaCalendar, FaList, FaPlus } from 'react-icons/fa'; 
import { UserContext } from '../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { addMonths, subMonths, startOfMonth, endOfMonth, format } from 'date-fns';
import Button from 'components/UI/Button/Button';
import Tabs from 'components/UI/Tabs/Tabs';
import IconButton from 'components/UI/IconButton/IconButton';
import FormCheckbox from 'components/UI/Form/FormCheckbox';
// Set up the localizer for react-big-calendar
const localizer = momentLocalizer(moment);

// Custom toolbar component for BigCalendar
const CustomToolbar = (toolbar) => {
  const goToBack = () => {
    toolbar.onNavigate('PREV');
  };

  const goToNext = () => {
    toolbar.onNavigate('NEXT');
  };

  const goToCurrent = () => {
    toolbar.onNavigate('TODAY');
  };

  // Get the view names in a more compact format
  const viewNames = {
    [Views.MONTH]: 'M',
    [Views.WEEK]: 'W',
    [Views.DAY]: 'D',
    [Views.AGENDA]: 'A'
  };

  return (
    <div className="flex items-center justify-between mb-2 text-sm">
      <div className="flex items-center gap-1">
        <button
          type="button"
          onClick={goToBack}
          className="px-2 py-1 bg-primary text-white hover:bg-primary-dark rounded"
        >
          &lt;
        </button>
        <button
          type="button"
          onClick={goToCurrent}
          className="px-2 py-1 bg-primary-lighter hover:bg-primary rounded mx-1"
        >
          Today
        </button>
        <button
          type="button"
          onClick={goToNext}
          className="px-2 py-1 bg-primary text-white hover:bg-primary-dark rounded"
        >
          &gt;
        </button>
      </div>
      
      <span className="font-medium text-center">
        {toolbar.label}
      </span>
      
      <div className="flex rounded overflow-hidden border border-gray-300">
        {toolbar.views.map(view => (
          <button
            key={view}
            type="button"
            onClick={() => toolbar.onView(view)}
            className={`
              px-2 py-1 text-xs sm:text-sm
              ${view === toolbar.view ? 'bg-primary-light text-white' : 'bg-white text-gray-700'}
            `}
          >
            {/* Show full names on larger screens, abbreviations on mobile */}
            <span className="hidden sm:inline">{view}</span>
            <span className="sm:hidden">{viewNames[view]}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default function Calendar() {
  const [jobs, setJobs] = useState([]);
  const [properties, setProperties] = useState([]);
  const [cleaners, setCleaners] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [filters, setFilters] = useState({
    status: '',
    propertyId: 'all',
    cleanerId: 'all',
    excludePastJobs: false,
  });
  const [viewMode, setViewMode] = useState('calendar');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isJobDetailsModalOpen, setIsJobDetailsModalOpen] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [jobsCache, setJobsCache] = useState({});
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState('month');
  const [hasNoProperties, setHasNoProperties] = useState(false);

  // Use effect to set the user role from context when component mounts
  useEffect(() => {
    if (user && user.current_role) {
      setUserRole(user.current_role);
    }
  }, [user]);

  // Fetch jobs for a specific month and cache them
  const fetchMonthJobs = async (date) => {
    const monthKey = format(date, 'yyyy-MM');
    
    // If we already have this month's data and it's not too old (e.g., 5 minutes)
    const cachedData = jobsCache[monthKey];
    if (cachedData && (Date.now() - cachedData.timestamp) < 300000) {
      return cachedData.data;
    }

    const startDate = format(startOfMonth(date), 'yyyy-MM-dd');
    const endDate = format(endOfMonth(date), 'yyyy-MM-dd');

    try {
      const data = await fetchJobs({ startDate, endDate });
      
      
      // Update cache with new data and timestamp
      setJobsCache(prev => ({
        ...prev,
        [monthKey]: {
          data,
          timestamp: Date.now()
        }
      }));

      return data;
    } catch (error) {
      console.error('Failed to fetch jobs', error);
      return null;
    }
  };

  // Load initial data and prefetch adjacent months
  const loadJobs = async () => {
    setIsLoading(true);
    try {
      // Fetch current month
      const currentMonthData = await fetchMonthJobs(currentDate);
      
      // Set initial data
      if (currentMonthData && currentMonthData.jobs && currentMonthData.jobs.length > 0) {
        setJobs(currentMonthData.jobs);
        setProperties(currentMonthData.properties);
        setCleaners(currentMonthData.cleaners);
        setFilteredJobs(currentMonthData.jobs);
        setCalendarEvents(formatEventsForCalendar(currentMonthData.jobs));
        setHasNoProperties(false);
      } else {
        // No jobs found, check if there are any properties
        try {
          const propertiesData = await fetchProperties();
          if (propertiesData && propertiesData.length > 0) {
            // Has properties but no jobs
            setProperties(propertiesData);
            setJobs([]);
            setFilteredJobs([]);
            setCalendarEvents([]);
            setHasNoProperties(false);
          } else {
            // No properties found
            setHasNoProperties(true);
          }
        } catch (error) {
          console.error('Failed to fetch properties', error);
          setHasNoProperties(true);
        }
      }

      // Prefetch next and previous months in background
      Promise.all([
        fetchMonthJobs(addMonths(currentDate, 1)),
        fetchMonthJobs(subMonths(currentDate, 1))
      ]);
    } catch (error) {
      console.error('Failed to fetch jobs', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle month navigation
  const handleRangeChange = async (range, view) => {
    console.log('Range change triggered:', { range, view });
    
    // Handle different range formats from react-big-calendar
    let newDate;
    if (Array.isArray(range)) {
      newDate = new Date(range[0]);
    } else if (range instanceof Date) {
      newDate = range;
    } else if (range.start) {
      // Get the middle date of the range to ensure we're getting the correct month
      const startDate = new Date(range.start);
      const endDate = new Date(range.end);
      newDate = new Date(startDate.getTime() + (endDate.getTime() - startDate.getTime()) / 2);
    } else {
      console.error('Unexpected range format:', range);
      return;
    }

    // Don't update if it's the same month
    const newMonthKey = format(newDate, 'yyyy-MM');
    const currentMonthKey = format(currentDate, 'yyyy-MM');
    if (newMonthKey === currentMonthKey) {
      return;
    }

    console.log('New date:', newDate);
    setCurrentDate(newDate);
    setIsLoading(true);

    try {
      // Get cached data for the new month
      const monthKey = format(newDate, 'yyyy-MM');
      console.log('Loading month:', monthKey);
      const cachedData = jobsCache[monthKey]?.data;

      if (cachedData) {
        console.log('Using cached data for:', monthKey);
        setJobs(cachedData.jobs);
        setProperties(cachedData.properties);
        setCleaners(cachedData.cleaners);
        setFilteredJobs(cachedData.jobs);
        setCalendarEvents(formatEventsForCalendar(cachedData.jobs));
      } else {
        console.log('Fetching new data for:', monthKey);
        const newMonthData = await fetchMonthJobs(newDate);
        if (newMonthData) {
          setJobs(newMonthData.jobs);
          setProperties(newMonthData.properties);
          setCleaners(newMonthData.cleaners);
          setFilteredJobs(newMonthData.jobs);
          setCalendarEvents(formatEventsForCalendar(newMonthData.jobs));
        }
      }

      // Prefetch adjacent months
      const prevMonth = subMonths(newDate, 1);
      const nextMonth = addMonths(newDate, 1);
      
      Promise.all([
        fetchMonthJobs(prevMonth),
        fetchMonthJobs(nextMonth)
      ]).catch(error => {
        console.error('Error prefetching adjacent months:', error);
      });

    } catch (error) {
      console.error('Error changing month:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle view changes
  const handleViewChange = (newView) => {
    setView(newView);
  };

  useEffect(() => {
    loadJobs();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filters, jobs, userRole]);

  const applyFilters = () => {
    let result = jobs;

    if (filters.status) {
      result = filterByStatus(result, filters.status);
    }

    if (filters.propertyId && filters.propertyId !== 'all') {
      result = filterByProperty(result, filters.propertyId);
    }

    if (filters.cleanerId && filters.cleanerId !== 'all') {
      console.log("The cleanerId is:", filters.cleanerId);
      result = filterByCleaner(result, filters.cleanerId);
    }

    if (filters.excludePastJobs) {
      result = filterOutPastJobs(result);
    }

    setFilteredJobs(result);
    setCalendarEvents(formatEventsForCalendar(result)); // Add this line
  };

  const toggleFilterModal = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  const filterByStatus = (jobsToFilter, status) => {
    switch (status) {
      case 'open':
        return userRole === 'manager'
          ? jobsToFilter.filter(job => job.status === 'open')
          : jobsToFilter.filter(job => job.status === 'open' && !job.job_applications.some(appl => appl.user_id === user.id));
      case 'applied':
        return userRole === 'manager'
          ? jobsToFilter.filter(job => job.status === 'applied')
          : jobsToFilter.filter(job => job.status === 'applied' && job.job_applications.some(appl => appl.user_id === user.id));
      case 'accepted':
        return jobsToFilter.filter(job => job.status === 'accepted');
      case 'completed':
        return jobsToFilter.filter(job => job.status === 'completed');
      default:
        return jobsToFilter;
    }
  };

  const filterByProperty = (jobsToFilter, propertyId) => {
    return jobsToFilter.filter(job => job.property_id === parseInt(propertyId));
  };

  const filterByCleaner = (jobsToFilter, cleanerId) => {
    return jobsToFilter.filter(job => job.cleaner?.id === parseInt(cleanerId));
  };

  const filterOutPastJobs = (jobsToFilter) => {
    const now = new Date();
    return jobsToFilter.filter(job => new Date(job.date_of_job) >= now);
  };

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  const handleJobClick = (job) => {
    setSelectedJob(job.id);
    setIsJobDetailsModalOpen(true);
  };

  const closeJobDetailsModal = (e) => {
    if (!e || e.target.classList.contains(styles.fullPageModalOverlay)) {
      setIsJobDetailsModalOpen(false);
      setSelectedJob(null);
    }
  };

  const formatEventsForCalendar = (jobs) => {
    return jobs.map(job => ({
      id: job.id,
      title: job.property.title,
      start: new Date(`${job.date_of_job}T${job.cleaning_from}`),
      end: new Date(`${job.date_of_job}T${job.cleaning_until}`),
      status: job.status, 
      price: `${job.price_cents / 100} ${job.price_currency}`,
      job: job,
    }));
  };

  const eventStyleGetter = (event) => {
    const statusClass = styles[event.status] || styles.default;
    return {
      className: `${styles.event} ${statusClass}`
    };
  };

  const handleDeleteJob = async (jobId, event) => {
    event.stopPropagation();
    if (window.confirm('Are you sure you want to delete this job?')) {
      try {
        await deleteJob(jobId);
        handleJobDeleted(jobId);
      } catch (error) {
        console.error('Error deleting job:', error);
      }
    }
  };

  const handleJobDeleted = (deletedJobId) => {
    setJobs(prevJobs => prevJobs.filter(job => job.id !== deletedJobId));
    setFilteredJobs(prevFilteredJobs => prevFilteredJobs.filter(job => job.id !== deletedJobId));
    setCalendarEvents(prevEvents => prevEvents.filter(event => event.id !== deletedJobId));
  };

  const handleAcceptJob = async (jobId, event) => {
    event.stopPropagation();
    try {
      const updatedJob = await acceptJob(jobId);
      
      // Update jobs, filteredJobs, and calendarEvents
      const updateJob = (job) => job.id === jobId ? { ...job, status: updatedJob.status } : job;
      
      setJobs(prevJobs => prevJobs.map(updateJob));
      setFilteredJobs(prevFilteredJobs => prevFilteredJobs.map(updateJob));
      setCalendarEvents(prevEvents => prevEvents.map(event => 
        event.id === jobId ? { ...event, status: updatedJob.status } : event
      ));

      // The modal will close automatically as it's already handled in JobDetails
    } catch (error) {
      console.error('Error accepting job:', error);
      throw error;
    }
  };

  const handleReopenJob = async (jobId, event) => {
    event.stopPropagation();
    try {
      const reopenedJob = await reopenJob(jobId);

      // Update jobs, filteredJobs, and calendarEvents
      const updateJob = (job) => job.id === jobId ? { ...job, status: reopenedJob.status } : job;
      
      setJobs(prevJobs => prevJobs.map(updateJob));
      setFilteredJobs(prevFilteredJobs => prevFilteredJobs.map(updateJob));
      setCalendarEvents(prevEvents => prevEvents.map(event => 
        event.id === jobId ? { ...event, status: reopenedJob.status } : event
      ));
    } catch (error) {
      console.error('Error reopening job:', error);
      throw error;
    }
  };

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex flex-row justify-between items-center mb-2 gap-2 w-full">
        <Tabs
          tabs={[
            { id: 'calendar', label: 'Calendar View' },
            { id: 'list', label: 'List View' }
          ]}
          activeTab={viewMode}
          onChange={handleViewModeChange}
          className="flex-shrink"
          size="sm"
        />
        
        <div className="flex items-center gap-2 flex-shrink-0">
          <IconButton 
            icon={<FaFilter />}
            onClick={toggleFilterModal}
            variant="outline"
            title="Filter Jobs"
            size="sm"
            className="h-8 w-8"
          />
          
          {userRole === 'manager' && (
            <Button 
              variant="primary" 
              onClick={() => navigate('/jobs/new')}
              className="flex items-center gap-1 h-8 px-2"
              size="sm"
            >
              <FaPlus /> <span>Add Job</span>
            </Button>
          )}
        </div>
      </div>

      {isFilterModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg max-w-md w-full mx-4">
            <h2 className="text-xl font-bold mb-4">Filters</h2>
            <div className="space-y-3 mb-4">
              <div className="flex flex-col">
                <label htmlFor="status" className="font-medium mb-1">Status:</label>
                <select 
                  id="status" 
                  name="status" 
                  value={filters.status} 
                  onChange={handleFilterChange}
                  className="p-2 border border-gray-300 rounded"
                >
                  <option value="">All Statuses</option>
                  <option value="open">Open</option>
                  <option value="applied">Applied</option>
                  <option value="accepted">Accepted</option>
                  <option value="completed">Completed</option>
                </select>
              </div>

              <div className="flex flex-col">
                <label htmlFor="propertyId" className="font-medium mb-1">Property:</label>
                <select 
                  id="propertyId" 
                  name="propertyId" 
                  value={filters.propertyId} 
                  onChange={handleFilterChange}
                  className="p-2 border border-gray-300 rounded"
                >
                  <option value="all">All Properties</option>
                  {(properties || []).map(property => (
                    <option key={property.id} value={property.id}>{property.title}</option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label htmlFor="cleanerId" className="font-medium mb-1">Cleaner:</label>
                <select 
                  id="cleanerId" 
                  name="cleanerId" 
                  value={filters.cleanerId} 
                  onChange={handleFilterChange}
                  className="p-2 border border-gray-300 rounded"
                >
                  <option value="all">All Cleaners</option>
                  {(cleaners || []).map(cleaner => (
                    <option key={cleaner.id} value={cleaner.id}>
                      {cleaner.first_name} {cleaner.last_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <FormCheckbox
                  id="excludePastJobs"
                  name="excludePastJobs"
                  checked={filters.excludePastJobs}
                  onChange={handleFilterChange}
                  label="Exclude Past Jobs"
                />
              </div>
            </div>
            <Button 
              onClick={toggleFilterModal} 
              variant="outline"
              className="w-full"
            >
              Close
            </Button>
          </div>
        </div>
      )}

      {isJobDetailsModalOpen && selectedJob && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" 
          onClick={closeJobDetailsModal}
        >
          <div className="bg-white rounded-lg w-11/12 h-5/6 overflow-auto">
            <JobDetails 
              jobId={selectedJob} 
              onClose={() => closeJobDetailsModal()} 
              user={user} 
              onJobDeleted={handleJobDeleted}
              handleAcceptJob={handleAcceptJob}
              handleReopenJob={handleReopenJob}
            />
          </div>
        </div>
      )}

      {hasNoProperties && userRole === 'manager' && (
        <div className="flex justify-center items-center min-h-[400px] w-full my-4">
          <div className="text-center bg-gray-50 rounded-lg p-8 max-w-md shadow-sm border border-gray-200">
            <h2 className="text-xl font-bold mb-3 text-gray-800">No Properties Found</h2>
            <p className="mb-5 text-gray-600">You don't have any properties yet. Create a property to start adding cleaning jobs.</p>
            <button 
              onClick={() => navigate('/properties/new')} 
              className="bg-primary text-white px-4 py-2 rounded-full font-medium hover:bg-primary-dark transition-colors inline-flex items-center gap-2"
            >
              Create Property
            </button>
          </div>
        </div>
      )}

      {viewMode === 'calendar' ? (
        <div className="flex-grow md:h-[calc(100vh-140px)] h-[calc(100vh-130px)]">
          {isLoading && <div className="flex justify-center items-center h-full">Loading...</div>}
          {(userRole !== 'manager' || !hasNoProperties) && !isLoading && (
            <BigCalendar
              localizer={localizer}
              events={calendarEvents}
              startAccessor="start"
              endAccessor="end"
              style={{ height: '100%' }}
              eventPropGetter={eventStyleGetter}
              onSelectEvent={(event) => handleJobClick(event.job)}
              onRangeChange={handleRangeChange}
              defaultView="month"
              view={view}
              onView={handleViewChange}
              components={{
                toolbar: CustomToolbar
              }}
              views={[Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]}
            />
          )}
        </div>
      ) : (
        <div className="space-y-4 mt-2 pb-4 md:pb-4 md:overflow-y-auto md:h-[calc(100vh-140px)]">
          {!hasNoProperties && filteredJobs.length === 0 && !isLoading && (
            <div className="text-center py-10 bg-gray-50 rounded-lg">
              <p className="text-gray-600">No jobs found for the current filters.</p>
            </div>
          )}
          {!hasNoProperties && filteredJobs.map((job) => (
            <div 
              key={job.id} 
              className="border border-gray-200 rounded-lg p-4 bg-gray-50 cursor-pointer hover:bg-gray-100 transition-colors"
              onClick={() => handleJobClick(job)}
            >
              <div className="flex justify-between items-center mb-2">
                <h3 className="font-bold text-lg">{job.property.title}</h3>
                {userRole === 'manager' && (
                  <button 
                    onClick={(e) => handleDeleteJob(job.id, e)} 
                    className="text-gray-500 hover:text-red-500 transition-colors p-1" 
                    title="Delete Job"
                  >
                    <FaTrash />
                  </button>
                )}
              </div>
              <p className="text-sm">Date: {new Date(job.date_of_job).toLocaleDateString()}</p>
              <p className="text-sm">Time: {job.cleaning_from} - {job.cleaning_until}</p>
              <p className="text-sm">Status: {job.status}</p>
              <p className="text-sm">Price: {job.price_cents / 100} {job.price_currency}</p>
              {job.status === 'open' && userRole !== 'manager' && (
                <button 
                  onClick={(e) => handleAcceptJob(job.id, e)}
                  className="mt-2 bg-green-600 text-white px-3 py-1 rounded text-sm hover:bg-green-700 transition-colors"
                >
                  Accept Job
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
