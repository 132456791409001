import React from 'react';

/**
 * Alert component for displaying informational messages
 * @param {Object} props - Component props
 * @param {string} props.variant - Alert variant ('info', 'success', 'warning', 'error')
 * @param {React.ReactNode} props.children - Alert content
 * @param {string} props.className - Additional CSS classes
 */
const Alert = ({
  variant = 'info',
  children,
  className = '',
  ...props
}) => {
  // Define variant styles
  const variants = {
    info: 'bg-blue-50 border-blue-200 text-blue-800',
    success: 'bg-green-50 border-green-200 text-green-800',
    warning: 'bg-yellow-50 border-yellow-200 text-yellow-800',
    error: 'bg-red-50 border-red-200 text-red-800',
    primary: 'bg-primary-lightest border-primary-light text-primary-darker',
  };

  return (
    <div 
      className={`border px-4 py-3 rounded ${variants[variant]} ${className}`}
      role="alert"
      {...props}
    >
      {children}
    </div>
  );
};

export default Alert;