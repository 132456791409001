import React from 'react';
import Select from 'react-select';

/**
 * SelectInput component for selecting a single option from a dropdown
 * @param {Object} props - Component props
 * @param {string} props.label - Input label
 * @param {string} props.id - Input ID
 * @param {string} props.name - Input name
 * @param {Array} props.options - Array of options {value, label}
 * @param {Object} props.value - Selected option {value, label}
 * @param {Function} props.onChange - Function called when selection changes
 * @param {string} props.error - Error message
 * @param {string} props.helpText - Help text
 * @param {boolean} props.required - Whether the input is required
 * @param {string} props.className - Additional CSS classes
 * @param {boolean} props.isClearable - Whether the selection can be cleared
 * @param {boolean} props.isSearchable - Whether the options can be searched
 */
const SelectInput = ({
  label,
  id,
  name,
  options,
  value,
  onChange,
  error,
  helpText,
  required = false,
  className = '',
  isClearable = false,
  isSearchable = true,
  ...props
}) => {
  // Custom styles to match Tailwind design
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: error ? '#ef4444' : state.isFocused ? '#5cc1c0' : '#d1d5db',
      boxShadow: state.isFocused ? '0 0 0 1px #5cc1c0' : provided.boxShadow,
      '&:hover': {
        borderColor: state.isFocused ? '#5cc1c0' : '#9ca3af',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#5cc1c0' : state.isFocused ? '#e6f7f7' : provided.backgroundColor,
      color: state.isSelected ? 'white' : provided.color,
      '&:active': {
        backgroundColor: '#5cc1c0',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#374151',
    }),
  };

  // Handle the onChange event to make it compatible with standard form handlers
  const handleChange = (selectedOption) => {
    if (typeof onChange === 'function') {
      // If it's a direct handler function
      onChange(selectedOption);
    } else if (onChange && onChange.target) {
      // If it's an event handler from a form
      const event = {
        target: {
          name,
          value: selectedOption ? selectedOption.value : '',
          type: 'select',
        },
      };
      onChange(event);
    }
  };

  return (
    <div className={className}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <Select
        id={id}
        name={name}
        options={options}
        value={value}
        onChange={handleChange}
        styles={customStyles}
        className="react-select-container"
        classNamePrefix="react-select"
        isClearable={isClearable}
        isSearchable={isSearchable}
        {...props}
      />
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
      {helpText && (
        <p className="mt-1 text-sm text-gray-500">{helpText}</p>
      )}
    </div>
  );
};

export default SelectInput; 