import React from 'react';

/**
 * IconButton component for circular icon buttons
 * @param {Object} props - Component props
 * @param {ReactNode} props.icon - Icon to display
 * @param {Function} props.onClick - Function called when button is clicked
 * @param {string} props.variant - Button variant (primary, secondary, etc.)
 * @param {string} props.size - Button size (sm, md, lg)
 * @param {string} props.className - Additional CSS classes
 * @param {string} props.title - Button tooltip text
 */
const IconButton = ({
  icon,
  onClick,
  variant = 'primary',
  size = 'md',
  className = '',
  title,
  ...props
}) => {
  // Define variant styles
  const variants = {
    primary: 'bg-primary hover:bg-primary-dark text-white',
    secondary: 'bg-secondary hover:bg-secondary-dark text-white',
    outline: 'bg-white border border-primary text-primary hover:bg-primary-lightest',
    ghost: 'bg-transparent hover:bg-primary-lightest text-primary-darker',
  };

  // Define size styles
  const sizes = {
    sm: 'p-1.5 text-sm',
    md: 'p-2 text-base',
    lg: 'p-2.5 text-lg',
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className={`
        rounded-full flex items-center justify-center transition-colors duration-200
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary/50
        ${variants[variant]} ${sizes[size]} ${className}
      `}
      title={title}
      aria-label={title}
      {...props}
    >
      {icon}
    </button>
  );
};

export default IconButton; 