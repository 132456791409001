import React from "react"
import { getStartOfMonth, getEndOfMonth } from "../../utils/dateUtils";
import styles from "./DateNavigation.module.css";
import Button from "../../../../../components/UI/Button/Button";

export default function DateNavigation(props) {

  function handlePrevious(){
    const newFrom = getStartOfMonth(new Date(props.from.getUTCFullYear(), props.from.getUTCMonth() - 1));
    const newUntil = getEndOfMonth(new Date(props.until.getUTCFullYear(), props.until.getUTCMonth() - 1));
    props.setFrom(newFrom);
    props.setUntil(newUntil);
  };

  function handleNext(){
    const newFrom = getStartOfMonth(new Date(props.from.getUTCFullYear(), props.from.getUTCMonth() + 1));
    const newUntil = getEndOfMonth(new Date(props.until.getUTCFullYear(), props.until.getUTCMonth() + 1));
    props.setFrom(newFrom);
    props.setUntil(newUntil);
  }

  // Function to format the month range
  const formatMonthRange = () => {
    const fromMonth = props.from.toLocaleDateString("default", { month: "long", timeZone: "UTC" });
    const fromYear = props.from.getUTCFullYear();
    const untilMonth = props.until.toLocaleDateString("default", { month: "long", timeZone: "UTC" });
    const untilYear = props.until.getUTCFullYear();

    if (fromYear < untilYear) {
      return `${fromMonth} ${fromYear} - ${untilMonth} ${untilYear}`;
    } else if (fromYear === untilYear) {
      if (props.from.getUTCMonth() === props.until.getUTCMonth()) {
        return fromMonth;
      } else if (props.from.getUTCMonth() < props.until.getUTCMonth()) {
        return `${fromMonth} - ${untilMonth}`;
      }
    }
    return "Date range is not valid";
  }

  function handleFromDateChange(event){
    props.setFrom(new Date(event.target.value));
  }

  function handleUntilDateChange(event){
    props.setUntil(new Date(event.target.value));
  }

  return (
    <div className={styles.card}>
      <div className={styles.dateNavigation}>
        <Button 
          variant="primary"
          size="sm"
          className="rounded-full w-12 h-12 flex items-center justify-center"
          onClick={handlePrevious} 
          aria-label="Previous Month"
        >
          &lt;
        </Button>
        <h2 className={styles.monthRange}>{formatMonthRange()}</h2>
        <Button 
          variant="primary"
          size="sm"
          className="rounded-full w-12 h-12 flex items-center justify-center"
          onClick={handleNext} 
          aria-label="Next Month"
        >
          &gt;
        </Button>
      </div>
      <div className={styles.dateInputContainer}>
        <p className={styles.description}>Select a date range to filter results:</p>
        <div className={styles.dateInput}>
          <div className={styles.dateField}>
            <label htmlFor="from_date">From</label>
            <input
              type="date"
              id="from_date"
              name="from_date"
              value={props.from.toISOString().split('T')[0]}
              onChange={handleFromDateChange}
              className={styles.datePicker}
            />
          </div>
          <div className={styles.dateField}>
            <label htmlFor="until_date">Until</label>
            <input
              type="date"
              id="until_date"
              name="until_date"
              value={props.until.toISOString().split('T')[0]}
              onChange={handleUntilDateChange}
              className={styles.datePicker}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
