import React, { useContext } from 'react';
import { FormDataContext } from '../PropertyFormWizard';
import RadioGroup from 'components/UI/RadioGroup/RadioGroup';
import Alert from 'components/UI/Alert/Alert';

export default function IntroductionStep() {
  const { formData, updateFormData } = useContext(FormDataContext);
  
  const handleModeChange = (selectedId) => {
    updateFormData({ isManualEntry: selectedId === 'manualEntry' });
  };
  
  const entryOptions = [
    { id: 'manualEntry', label: 'Enter Details Manually' },
    { id: 'airbnbImport', label: 'Import from Airbnb (Coming Soon)' }
  ];
  
  return (
    <div className="space-y-6">
      <p className="text-body mb-4">
        Welcome to the property creation wizard. You can add a new property by entering 
        the details manually or by importing from Airbnb.
      </p>
      
      <div className="flex justify-center mb-4">
        <RadioGroup
          options={entryOptions}
          name="entryMode"
          selectedValue={formData.isManualEntry ? 'manualEntry' : 'airbnbImport'}
          onChange={handleModeChange}
        />
      </div>
      
      {!formData.isManualEntry && (
        <Alert variant="info">
          Airbnb import functionality is coming soon. For now, please enter details manually.
        </Alert>
      )}
    </div>
  );
} 