import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './TaxMain.module.css';
import TaxNavbar from '../../components/TaxNavbar/TaxNavbar';

const TaxMain = () => {
  const navigate = useNavigate();
  const [taxData, setTaxData] = useState({});

  useEffect(() => {
    const fetchTaxData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_CONNECT_CLEAN_API_URL}/api/fetch_all_tax_data`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
        const data = await response.json();
        if (response.ok) {
          setTaxData(data.data);
          console.log(data.data);
        } else {
          console.error('Error fetching tax data:', data.error);
        }
      } catch (error) {
        console.error('Error fetching tax data:', error);
      }
    };

    fetchTaxData();
  }, []);

  const handleUploadClick = () => {
    navigate('/taxes/upload-earnings');
  };

  const handleViewDetailsClick = (yearData) => {
    navigate('/taxes/detail', { state: { data: yearData } });
  };

  const sortedYears = Object.keys(taxData).sort((a, b) => b - a);

  return (
    <div>
      <TaxNavbar />
      <div className={styles.taxPage}>
        <header className={styles.taxHeader}>
          <h1>ConnectClean</h1>
          <h2>Manage your Taxes</h2>
        </header>
        <button className={styles.uploadBtn} onClick={handleUploadClick}>Update Earnings</button>
        <section className={styles.taxSection}>
          {sortedYears.map(year => (
            <div key={year} className={styles.taxYear}>
              <h3>{parseInt(year) + 1} - Income {year}</h3>
              <div className={styles.summaryTotal}>
                <h4>Summary</h4>
                <div className={styles.summaryItem}>
                  <span className={styles.label}>Total Income:</span>
                  <span className={styles.value}>{taxData[year]['tax_data_total'].income}</span>
                </div>
                <div className={styles.summaryItem}>
                  <span className={styles.label}>Total Onroerende Inkomsten:</span>
                  <span className={styles.value}>{taxData[year]['tax_data_total'].onroerende_inkomsten}</span>
                </div>
                <div className={styles.summaryItem}>
                  <span className={styles.label}>Total Airbnb Service Fee:</span>
                  <span className={styles.value}>{taxData[year]['tax_data_total'].airbnb_service_fee}</span>
                </div>
                <div className={styles.summaryItem}>
                  <span className={styles.label}>Total Diverse Inkomsten:</span>
                  <span className={styles.value}>{taxData[year]['tax_data_total'].diverse_inkomsten}</span>
                </div>
                <div className={styles.summaryItem}>
                  <span className={styles.label}>Total Cleaning Fees:</span>
                  <span className={styles.value}>{taxData[year]['tax_data_total'].cleaning_fees}</span>
                </div>
                <div className={styles.summaryItem}>
                  <span className={styles.label}>Total Nights:</span>
                  <span className={styles.value}>{taxData[year]['tax_data_total'].total_nights}</span>
                </div>
              </div>
              <button
                className={styles.detailBtn}
                onClick={() => handleViewDetailsClick({
                  tax_data_total: taxData[year]['tax_data_total'],
                  tax_data_per_property: taxData[year]['tax_data_per_property'],
                  earnings_year: year
                })}
              >
                View Details
              </button>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default TaxMain;
