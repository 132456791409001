import React, { useContext } from 'react';
import { FormDataContext } from '../PropertyFormWizard';
import { useNavigate } from 'react-router-dom';
import { createProperty } from 'api/propertiesApi';
import Button from 'components/UI/Button/Button';
import ReviewCard from './ReviewCard';

export default function ReviewStep() {
  const { formData } = useContext(FormDataContext);
  const navigate = useNavigate();
  
  const handleSubmit = async () => {
    try {
      // Prepare data for API
      const propertyData = {
        property: {
          title: formData.title,
          address: formData.address,
          square_meters: formData.square_meters,
          number_rooms: formData.number_rooms,
          number_bathrooms: formData.number_bathrooms,
          has_cleaning_supplies: formData.has_cleaning_supplies,
          description: formData.description,
          default_job_price: formData.default_job_price,
          default_job_price_currency: formData.default_job_price_currency,
          default_cleaning_from: formData.default_cleaning_from,
          default_cleaning_until: formData.default_cleaning_until,
          photo: formData.photo,
        },
        managers: formData.managers,
        cleaners: formData.cleaners,
      };
      
      // Add airbnb_import if it exists
      if (formData.airbnb_import) {
        propertyData.airbnb_import = formData.airbnb_import;
      }
      
      await createProperty(propertyData);
      navigate('/properties'); // Redirect to the properties index after successful creation
    } catch (error) {
      console.error('Failed to create property:', error);
      alert('There was an error creating the property. Please try again.');
    }
  };
  
  return (
    <div className="space-y-6">
      <p className="text-gray-700">
        Please review your property information before submitting.
      </p>
      
      <div className="space-y-4">
        <ReviewCard title="Basic Information" stepNumber={1}>
          <div className="space-y-2">
            <p className="text-gray-700"><span className="font-medium">Title:</span> {formData.title}</p>
            <p className="text-gray-700"><span className="font-medium">Address:</span> {formData.address}</p>
            <p className="text-gray-700"><span className="font-medium">Photo:</span> {formData.photo ? 'Uploaded' : 'Not uploaded'}</p>
          </div>
        </ReviewCard>
        
        <ReviewCard title="Budget" stepNumber={2}>
          <div className="space-y-2">
            <p className="text-gray-700"><span className="font-medium">Price:</span> {formData.default_job_price} {formData.default_job_price_currency}</p>
          </div>
        </ReviewCard>
        
        <ReviewCard title="Schedule" stepNumber={3}>
          <div className="space-y-2">
            <p className="text-gray-700"><span className="font-medium">Cleaning Time:</span> {formData.default_cleaning_from} - {formData.default_cleaning_until}</p>
          </div>
        </ReviewCard>
        
        <ReviewCard title="Team Assignment" stepNumber={4}>
          <div className="space-y-2">
            <p className="text-gray-700"><span className="font-medium">Managers:</span> {formData.managers.length > 0 ? formData.managers.length + ' assigned' : 'None assigned'}</p>
            <p className="text-gray-700"><span className="font-medium">Cleaners:</span> {formData.cleaners.length > 0 ? formData.cleaners.length + ' assigned' : 'None assigned'}</p>
          </div>
        </ReviewCard>
        
        {formData.airbnb_import && (
          <ReviewCard title="Calendar Integration" stepNumber={5}>
            <div className="space-y-2">
              <p className="text-gray-700"><span className="font-medium">iCal URL:</span> {formData.airbnb_import.ical_url ? 'Provided' : 'Not provided'}</p>
              <p className="text-gray-700"><span className="font-medium">Import Weeks:</span> {formData.airbnb_import.import_weeks}</p>
              <p className="text-gray-700"><span className="font-medium">Auto Post:</span> {formData.airbnb_import.auto_post ? 'Yes' : 'No'}</p>
            </div>
          </ReviewCard>
        )}
        
        <ReviewCard title="Property Details" stepNumber={6}>
          <div className="space-y-2">
            <p className="text-gray-700"><span className="font-medium">Square Meters:</span> {formData.square_meters || 'Not specified'}</p>
            <p className="text-gray-700"><span className="font-medium">Number of Rooms:</span> {formData.number_rooms || 'Not specified'}</p>
            <p className="text-gray-700"><span className="font-medium">Number of Bathrooms:</span> {formData.number_bathrooms || 'Not specified'}</p>
            <p className="text-gray-700"><span className="font-medium">Has Cleaning Supplies:</span> {formData.has_cleaning_supplies ? 'Yes' : 'No'}</p>
            <p className="text-gray-700"><span className="font-medium">Description:</span> {formData.description ? formData.description : 'Not provided'}</p>
          </div>
        </ReviewCard>
      </div>
    </div>
  );
}
