import React from 'react';

const Button = ({ 
  children, 
  variant = 'primary', 
  size = 'md',
  type = 'button',
  onClick,
  className = '',
  disabled = false,
  ...props 
}) => {
  // Define variant styles using only colors from your existing palette
  const variants = {
    primary: 'bg-primary hover:bg-primary-dark text-white',
    secondary: 'bg-secondary hover:bg-secondary-dark text-white',
    outline: 'bg-white border border-primary text-primary hover:bg-primary-lightest',
    danger: 'bg-danger hover:bg-danger-dark text-white',
    success: 'bg-success hover:bg-success-dark text-white',
    ghost: 'bg-transparent hover:bg-primary-lightest text-primary-darker',
    link: 'bg-transparent text-primary hover:text-primary-dark underline p-0',
  };

  // Define size styles
  const sizes = {
    xs: 'py-1 px-2 text-xs',
    sm: 'py-1 px-3 text-sm',
    md: 'py-2 px-4 text-base',
    lg: 'py-3 px-6 text-lg',
    icon: 'p-2', // For icon-only buttons
  };

  // Base styles that apply to all buttons
  const baseStyles = 'font-medium rounded transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary/50 disabled:opacity-50 disabled:cursor-not-allowed';

  return (
    <button
      type={type}
      className={`${baseStyles} ${variants[variant]} ${sizes[size]} ${className}`}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
