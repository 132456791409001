import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import styles from './ManagerPropertyCard.module.css';
import { updateProperty, deleteProperty } from 'api/propertiesApi';
import { fetchUsers } from 'api/usersApi';
import MapComponent from '../../components/MapBox/MapComponent';
import { useNavigate } from 'react-router-dom';
import AddressAutoComplete from '../../components/MapBox/AddressAutoComplete';
import FileUpload from 'components/UI/Form/FileUpload';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCamera, 
  faHome, 
  faMapMarkerAlt, 
  faBroom, 
  faUsers, 
  faRobot, 
  faCog,
  faBed, 
  faBath, 
  faRuler, 
  faClock,
  faLocationDot,
  faUserTie,
  faUser,
  faImage,
  faPencilAlt, 
  faCheck, 
  faTimes,
  faTrash,
  faDollarSign,
  faEuroSign
} from '@fortawesome/free-solid-svg-icons';
import '../../config/icons';
import getSymbolFromCurrency from 'currency-symbol-map';
import { UserContext } from '../../../context/UserContext';

// Set the root element for accessibility
Modal.setAppElement('#root');

const getCurrencyIcon = (currency) => {
  return getSymbolFromCurrency(currency) || '$';
};

export default function ManagerPropertyCard({ property }) {
  const { user } = useContext(UserContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editData, setEditData] = useState({ ...property });
  const [activeSection, setActiveSection] = useState('');
  const [users, setUsers] = useState([]);
  const[assignedManagers, setAssignedManagers] = useState(
    property.teams.filter((t) => t.profession === 'manager')
    .map((m) => ({
      id: m.user.id,
      first_name: m.user.first_name,
      last_name: m.user.last_name
    }))
  );
  const [assignedCleaners, setAssignedCleaners] = useState(
    property.teams.filter((t) => t.profession === 'cleaner')
    .map((c) => ({
      id: c.user.id,
      first_name: c.user.first_name,
      last_name: c.user.last_name
    }))
  );

  const [airbnbImport, setAirbnbImport] = useState(property.airbnb_import);

  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);

  const [selectedManager, setSelectedManager] = useState(null);
  const [selectedCleaner, setSelectedCleaner] = useState(null);

  const [defaultJobPriceInput, setDefaultJobPriceInput] = useState(
    property.default_job_price_cents ? (property.default_job_price_cents / 100).toFixed(2) : ''
  );

  const [lastValidPriceInput, setLastValidPriceInput] = useState(defaultJobPriceInput);

  const navigate = useNavigate(); // Add this line

  // New state for editable title
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(property.title);

  // Fetch users when component mounts
  useEffect(() => {
    const loadUsers = async () => {
      try {
        const fetchedUsers = await fetchUsers();
        setUsers(fetchedUsers);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    };

    loadUsers();
  }, [property]);

  const availableManagers = users.filter(
    (user) => !assignedManagers.some((m) => m.id === user.id)
  )

  const availableCleaners = users.filter(
    (user) => !assignedCleaners.some((cleaner) => cleaner.id === user.id)
  );

  console.log('Assigned Managers:', assignedManagers);
  console.log('Assigned Cleaners:', assignedCleaners);
  console.log('Users:', users);
  console.log('Property:', property);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: name === 'has_cleaning_supplies' ? value === 'true' : value,
    }));
  };

  const saveChanges = async () => {
    console.log('Saving changes', editData);

    try {
      // Create a new FormData instance
      const formData = new FormData();

      // Define the permitted property fields
      const permittedFields = [
        'title',
        'address',
        'square_meters',
        'number_rooms',
        'number_bathrooms',
        'has_cleaning_supplies',
        'description',
        'default_job_price',
        'default_job_price_cents',
        'default_job_price_currency',
        'default_cleaning_from',
        'default_cleaning_until'
      ];

      // Append each permitted field to FormData
      permittedFields.forEach((field) => {
        if (editData[field] !== null && editData[field] !== undefined) {
          formData.append(`property[${field}]`, editData[field]);
        }
      });

      // Append managers
      assignedManagers.forEach((manager) => {
        formData.append('managers[]', manager.id);
      });

      // Append cleaners
      assignedCleaners.forEach((cleaner) => {
        formData.append('cleaners[]', cleaner.id);
      });

      // **Append `airbnb_import` as separate top-level parameters**
      if (airbnbImport) {
        formData.append('airbnb_import[ical_url]', airbnbImport.ical_url || '');
        formData.append('airbnb_import[import_weeks]', airbnbImport.import_weeks || '');
        formData.append('airbnb_import[auto_post]', airbnbImport.auto_post);
        formData.append('airbnb_import[post_all]', airbnbImport.post_all);
      }

      // Append the photo if a new one is selected
      if (selectedPhoto) {
        formData.append('property[photo]', selectedPhoto);
      }

      // Call the API to update the property
      const updatedProperty = await updateProperty(editData.id, formData);

      console.log('Property updated successfully:', updatedProperty);
      // Update the local state with the new data, including the new photo URL
      setEditData(updatedProperty);
      setSelectedPhoto(null); // Reset the selected photo
      // Close the modal after saving changes
      closeSection();
    } catch (error) {
      console.error('Failed to save property changes:', error);
      throw error; // Rethrow the error so it can be caught in handleTitleSave
    }
  };

  useEffect(() => {
    if (!selectedPhoto) {
      setPhotoPreview(null);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedPhoto);
    setPhotoPreview(objectUrl);

    // Clean up the object URL when the component unmounts or when selectedPhoto changes
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedPhoto]);



  const importListing = () => {
    console.log('Importing Airbnb Listing:', editData.airbnb_link);
    // Add your logic here to import the Airbnb listing details
    closeSection();
  };

  const importCalendar = async () => {
    try {
      // Call saveChanges to save the latest state to the backend
      await saveChanges();
      console.log('Changes saved after importing calendar.');
    } catch (error) {
      console.error('Error saving changes after calendar import:', error);
      alert('Failed to save changes after importing calendar. Please try again.');
    }
    closeSection();
  };

  const openSection = (section) => {
    setActiveSection(section);
    setModalIsOpen(true);
  };

  const closeSection = () => {
    setModalIsOpen(false);
    setActiveSection('');
  };

  const handleDeleteProperty = async () => {
    if (
      window.confirm(
        'Are you sure you want to permanently delete this property? This action cannot be undone.'
      )
    ) {
      try {
        await deleteProperty(editData.id);
        console.log('Property deleted successfully');
        navigate('/properties'); // Add this line to redirect after successful deletion
      } catch (error) {
        console.error('Failed to delete property:', error);
        alert('An error occurred while deleting the property. Please try again.');
      }
    }
  };

  const handleTitleCancel = () => {
    setIsEditingTitle(false);
  };

  return (
    <div className={styles.propertyCardsContainer}>
      {/* Centered Editable Title */}
      <div className={`${styles.centeredTitleContainer} mb-4`}>
        {isEditingTitle ? (
          <div className="d-flex justify-content-center align-items-center">
            <input
              type="text"
              name="title"  // Add this line
              value={editData.title}  // Change this line
              onChange={handleInputChange}  // Change this line
              className="form-control mr-2"
              style={{ width: 'auto' }}
            />
            <button onClick={() => {
              saveChanges();
              setIsEditingTitle(false);
            }} className="btn btn-primary mr-2">
              <FontAwesomeIcon icon={faCheck} />
            </button>
            <button onClick={handleTitleCancel} className="btn btn-secondary">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        ) : (
          <h1 className={styles.centeredTitle}>
            {editData.title}
            <button onClick={() => setIsEditingTitle(true)} className="btn btn-link">
              <FontAwesomeIcon icon={faPencilAlt} />
            </button>
          </h1>
        )}
      </div>

      {/* Pictures Card */}
      <div className={styles.propertyCard} onClick={() => openSection('Pictures')}>
        <h4 className={styles.cardTitle}>
          <FontAwesomeIcon icon={faCamera} className={styles.icon} />
          <span>Pictures</span>
        </h4>
        <div className={styles.imageContainer}>
          {editData.photo_url ? (
            <img 
              src={editData.photo_url} 
              alt="Property" 
              className={styles.propertyImage}
            />
          ) : (
            <div className={styles.imagePlaceholder}>
              <FontAwesomeIcon icon={faImage} size="2x" />
              <span>Add property photos</span>
            </div>
          )}
        </div>
      </div>

      {/* Property Description Card */}
      <div className={styles.propertyCard} onClick={() => openSection('Property Description')}>
        <h4 className={styles.cardTitle}>
          <FontAwesomeIcon icon={faHome} className={styles.icon} />
          <span>Property Description</span>
        </h4>
        <div className={styles.statsContainer}>
          <div className={styles.statItem}>
            <FontAwesomeIcon icon={faBed} className={styles.icon} />
            <span className={styles.statValue}>{editData.number_rooms}</span>
            <span>Rooms</span>
          </div>
          <div className={styles.statItem}>
            <FontAwesomeIcon icon={faBath} className={styles.icon} />
            <span className={styles.statValue}>{editData.number_bathrooms}</span>
            <span>Baths</span>
          </div>
          <div className={styles.statItem}>
            <FontAwesomeIcon icon={faRuler} className={styles.icon} />
            <span className={styles.statValue}>{editData.square_meters}</span>
            <span>m²</span>
          </div>
        </div>
        <p className={styles.cardContent}>{editData.description}</p>
      </div>

      {/* Location Card */}
      <div className={styles.propertyCard} onClick={() => openSection('Location')}>
        <h4 className={styles.cardTitle}>
          <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icon} />
          <span>Location</span>
        </h4>
        <div className={styles.mapContainer}>
          <MapComponent 
            address={editData.address}
            onAddressChange={() => {}}
            isPreview={true}
          />
        </div>
        <div className={styles.locationAddress}>
          <FontAwesomeIcon icon={faLocationDot} className={styles.icon} />
          <span>{editData.address}</span>
        </div>
      </div>

      {/* Cleaning Instructions Card - updated styling only */}
      <div className={styles.propertyCard} onClick={() => openSection('Cleaning Instructions')}>
        <h4 className={styles.cardTitle}>
          <FontAwesomeIcon icon={faBroom} className={styles.icon} />
          <span>Cleaning Instructions</span>
        </h4>
        <div className={styles.timeDisplay}>
          <FontAwesomeIcon icon={faClock} className={styles.icon} />
          <span>{editData.default_cleaning_from} - {editData.default_cleaning_until}</span>
        </div>
        <div className={styles.statusIndicator}>
          <div className={`${styles.statusDot} ${editData.has_cleaning_supplies ? styles.statusActive : styles.statusInactive}`} />
          <span>Cleaning Supplies {editData.has_cleaning_supplies ? 'Available' : 'Not Available'}</span>
        </div>
      </div>

      {/* My Team Card - updated styling only */}
      <div className={styles.propertyCard} onClick={() => openSection('My Team')}>
        <h4 className={styles.cardTitle}>
          <FontAwesomeIcon icon={faUsers} className={styles.icon} />
          <span>My Team</span>
        </h4>
        <div className={styles.teamContainer}>
          <div className={styles.teamGroup}>
            {assignedManagers.map((manager) => (
              <span key={manager.id} className={`${styles.teamMember} ${styles.managerBadge}`}>
                <FontAwesomeIcon icon={faUserTie} className={styles.icon} />
                {manager.first_name}{manager.id === user?.id ? " (You)" : ""}
              </span>
            ))}
          </div>
          <div className={styles.teamGroup}>
            {assignedCleaners.map((cleaner) => (
              <span key={cleaner.id} className={`${styles.teamMember} ${styles.cleanerBadge}`}>
                <FontAwesomeIcon icon={faUser} className={styles.icon} />
                {cleaner.first_name}{cleaner.id === user?.id ? " (You)" : ""}
              </span>
            ))}
          </div>
        </div>
      </div>

      {/* Default Price Card */}
      <div className={styles.propertyCard} onClick={() => openSection('Default Price')}>
        <h4 className={styles.cardTitle}>
          <span className={styles.currencySymbol}>
            {getCurrencyIcon(editData.default_job_price_currency)}
          </span>
          <span>Default Price</span>
        </h4>
        <div className={styles.priceDisplay}>
          <span className={styles.priceValue}>
            {(editData.default_job_price_cents / 100).toFixed(2)}
          </span>
          <span className={styles.currencyCode}>
            {editData.default_job_price_currency}
          </span>
        </div>
      </div>

      {/* Automate Card - updated styling only */}
      <div className={styles.propertyCard} onClick={() => openSection('Automate')}>
        <h4 className={styles.cardTitle}>
          <FontAwesomeIcon icon={faRobot} className={styles.icon} />
          <span>Automate</span>
        </h4>
        <div className={styles.statusIndicator}>
          <div className={`${styles.statusDot} ${airbnbImport?.auto_post ? styles.statusActive : styles.statusInactive}`} />
          <span>Automation {airbnbImport?.auto_post ? 'Active' : 'Inactive'}</span>
        </div>
      </div>

      {/* More Options Card - updated styling only */}
      <div className={styles.propertyCard} onClick={() => openSection('More Options')}>
        <h4 className={styles.cardTitle}>
          <FontAwesomeIcon icon={faCog} className={styles.icon} />
          <span>More Options</span>
        </h4>
        <p className={styles.cardContent}>Manage advanced settings or delete this property.</p>
      </div>

      {/* Full-Screen Modal for editing sections */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeSection}
        className={styles.popupModal}
        overlayClassName={styles.modalOverlay}
        shouldCloseOnOverlayClick={true}
      >
        <div className={styles.modalHeader}>
          <button className={styles.closeButton} onClick={closeSection}>
            ✕
          </button>
          <h2 className={styles.modalTitle}>{activeSection}</h2>
        </div>
        <div className={styles.modalBody}>
          {/* Update from Airbnb Section */}
          {activeSection === 'Update from Airbnb' && (
            <>
              {/* Airbnb Listing URL */}
              <div className="mb-1">
                <label className="form-label">Airbnb Listing URL</label>
                <input
                  type="text"
                  name="airbnb_link"
                  value={editData.airbnb_link || ''}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter your Airbnb listing URL"
                />
                <small className="text-muted">
                  Enter your Airbnb listing URL to update your property details automatically.
                  This will import information like your title, description, and room details directly from Airbnb, saving you time on manual updates.
                </small>
              </div>
              <div className="d-flex justify-content-start mt-3">
                <button className="btn btn-primary" onClick={importListing}>
                  Import Listing
                </button>
              </div>

              {/* Centered Close Button at the Bottom */}
              <div className="d-flex justify-content-center mt-auto">
                <button className="btn btn-secondary" onClick={closeSection}>
                  Close
                </button>
              </div>
            </>
          )}
          {/* Title Section */}
          {activeSection === 'Title' && (
            <>
              <input
                type="text"
                name="title"
                value={editData.title}
                onChange={handleInputChange}
                className="form-control"
              />
            </>
          )}

          {/* Pictures Section */}
          {activeSection === 'Pictures' && (
            <div className={styles.picturesSection}>
              {/* Image Preview Area */}
              <div className={styles.imagePreviewArea}>
                {photoPreview || editData.photo_url ? (
                  <img
                    src={photoPreview || editData.photo_url}
                    alt="Property Photo"
                    className={styles.modalImage}
                  />
                ) : (
                  <div className={styles.modalImagePlaceholder}>
                    <FontAwesomeIcon icon={faImage} size="3x" />
                    <span>No image available</span>
                  </div>
                )}
              </div>
              
              {/* File Upload Component - Always visible */}
              <div className={styles.fileUploadContainer}>
                <FileUpload
                  label="Upload Property Photo"
                  id="property-photo"
                  name="photo"
                  accept="image/*"
                  onChange={(file) => setSelectedPhoto(file)}
                  preview={photoPreview}
                  previewAlt="Property photo preview"
                  showPreview={false} // Don't show preview in component since we have it above
                  className="mt-3"
                />
              </div>
              
              <div className={styles.uploadInstructions}>
                <p>Select an image file to upload as the property photo. This will be displayed on the property card and in listings.</p>
                <p>Recommended: Square images with minimum dimensions of 800x800 pixels.</p>
              </div>
            </div>
          )}


          {/* Property Description Section */}
          {activeSection === 'Property Description' && (
            <div className={styles.modalBody}>
              {/* Description Section */}
              <div className="mb-3" style={{ flexGrow: 1 }}>
                <label htmlFor="description" className="form-label">Description of the property</label>
                <textarea
                  id="description"
                  name="description"
                  value={editData.description}
                  onChange={handleInputChange}
                  className={`${styles['description-textarea']} form-control`}
                  style={{ height: '100%', minHeight: '100px' }} /* Ensure it occupies the available height */
                />
              </div>

              {/* Number of Rooms and Bathrooms Section */}
              <div className={`${styles.inputSection} mt-2`}>
                {/* Number of Rooms */}
                <div className={styles.inputRow}>
                  <label htmlFor="number_rooms" className={`${styles.labelField} form-label`}>
                    Number of Rooms:
                  </label>
                  <input
                    id="number_rooms"
                    type="number"
                    name="number_rooms"
                    value={editData.number_rooms}
                    onChange={handleInputChange}
                    className={`${styles.smallInput} form-control`}
                  />
                </div>
                {/* Number of Bathrooms */}
                <div className={styles.inputRow}>
                  <label htmlFor="number_bathrooms" className={`${styles.labelField} form-label`}>
                    Number of Bathrooms:
                  </label>
                  <input
                    id="number_bathrooms"
                    type="number"
                    name="number_bathrooms"
                    value={editData.number_bathrooms}
                    onChange={handleInputChange}
                    className={`${styles.smallInput} form-control`}
                  />
                </div>

                {/* Square Meters */}
                <div className={styles.inputRow}>
                  <label htmlFor="square_meters" className={`${styles.labelField} form-label`}>
                    Square meters of the property:
                  </label>
                  <input
                    id="square_meters"
                    type="number"
                    name="square_meters"
                    value={editData.square_meters}
                    onChange={handleInputChange}
                    className={`${styles.smallInput} form-control`}
                  />
                </div>

              </div>
            </div>
          )}

          {/* Location Section */}
          {activeSection === 'Location' && (
            <>
              <MapComponent 
                address={editData.address}
                onAddressChange={(newAddress) => {
                  setEditData(prevData => ({
                    ...prevData,
                    address: newAddress
                  }));
                }}
              />
              <div className="mt-3">
                <AddressAutoComplete
                  initialAddress={editData.address}
                  onAddressSelect={(newAddress) => {
                    setEditData(prevData => ({
                      ...prevData,
                      address: newAddress
                    }));
                  }}
                />
              </div>
            </>
          )}

          {/* Cleaning Instructions Section */}
          {activeSection === 'Cleaning Instructions' && (
            <>
              <small className="text-muted">
                  Write a brief description of the cleaning instructions for your property.
                  This will help your team understand the cleaning requirements and expectations.
                </small>
              <textarea
                name="cleaning_description"
                value={editData.cleaning_description || ''}
                onChange={handleInputChange}
                className="form-control"
                rows="7"
                placeholder="Enter cleaning instructions here"
              />
              <small className="text-muted mt-3">
                Specify the cleaning hours between which the property can be cleaned.
              </small>
              <div className="d-flex mt-1">
                <label htmlFor="default_cleaning_from" className="me-3">Between:</label>
                <input
                  type="time"
                  name="default_cleaning_from"
                  value={editData.default_cleaning_from}
                  onChange={handleInputChange}
                  className={`${styles.smallInput} form-control me-4`}
                />
                <label htmlFor="default_cleaning_until" className="me-3">and:</label>
                <input
                  type="time"
                  name="default_cleaning_until"
                  value={editData.default_cleaning_until}
                  onChange={handleInputChange}
                  className={`${styles.smallInput} form-control`}
                />
              </div>
              <div className="d-flex">
                <label htmlFor="has_cleaning_supplies" className="me-3">Are there cleaning supplies present at the property?</label>
                <select
                  name="has_cleaning_supplies"
                  value={editData.has_cleaning_supplies ? 'true' : 'false'}
                  onChange={handleInputChange}
                  className={`${styles.smallInput} form-control`}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </>
          )}

          {/* My Team Section */}
          {activeSection === 'My Team' && (
            <>
              <div className="mb-3">
                <p className={styles.cardContent}>Managers:</p>
                {assignedManagers.map((manager) => (
                  <div key={manager.id} className="d-flex align-items-center mb-2">
                    <span className="me-2">
                      {`${manager.first_name} ${manager.last_name}`}{manager.id === user?.id ? " (You)" : ""}
                    </span>
                    {manager.id !== user?.id && (
                      <button
                        className={styles.trashIconButton}
                        onClick={() =>
                          setAssignedManagers((prev) =>
                          prev.filter((m) => m.id !== manager.id)
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} className={styles.trashIcon} />
                    </button>
                    )}
                  </div>
                ))}
                <Select
                  options={availableManagers.map((user) => ({
                    value: user.id,
                    label: `${user.first_name} ${user.last_name}`,
                    user: user,
                  }))}
                  onChange={(selectedOption) => {
                    setAssignedManagers((prev) => [
                      ...prev,
                      { id: selectedOption.user.id,
                        first_name: selectedOption.user.first_name,
                        last_name: selectedOption.user.last_name
                      },
                    ])
                    setSelectedManager(null)
                  }}
                  value={selectedManager}
                  className="basic-multi-select mb-3"
                  classNamePrefix="select"
                  placeholder="Add Manager"
                />
              </div>

              <div>
                <p className={styles.cardContent}>Cleaners:</p>
                {assignedCleaners.map((cleaner) => (
                  <div key={cleaner.id} className="d-flex align-items-center mb-2">
                    <span className="me-2">
                      {`${cleaner.first_name} ${cleaner.last_name}`}{cleaner.id === user?.id ? " (You)" : ""}
                    </span>
                    <button
                      className={styles.trashIconButton}
                      onClick={() =>
                        setAssignedCleaners((prev) =>
                          prev.filter((c) => c.id !== cleaner.id)
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} className={styles.trashIcon} />
                    </button>
                  </div>
                ))}
                <Select
                  options={availableCleaners.map((user) => ({
                    value: user.id,
                    label: `${user.first_name} ${user.last_name}`,
                    user: user,
                  }))}
                  onChange={(selectedOption) => {
                    setAssignedCleaners((prev) => [
                      ...prev,
                      { id: selectedOption.user.id,
                        first_name: selectedOption.user.first_name,
                        last_name: selectedOption.user.last_name },
                    ]);
                    setSelectedCleaner(null);
                  }}
                  value={selectedCleaner}
                  className="basic-multi-select mb-3"
                  classNamePrefix="select"
                  placeholder="Add Cleaner"
                />
              </div>
            </>
          )}

          {/* Automate Section */}
          {activeSection === 'Automate' && (
            <>
              <h5 className="form-label">Airbnb iCal Link</h5>
              <input
                type="text"
                name="ical_link"
                value={airbnbImport?.ical_url || ''}
                onChange={(e) => setAirbnbImport({ ...airbnbImport, ical_url: e.target.value })}
                className="form-control"
                placeholder="Enter your Airbnb iCal link"
              />
              <small className="text-muted">
                Enter your Airbnb iCal link to automatically create cleaning jobs when guests check out.
                This will ensure your property is always ready for the next guest without needing to schedule cleaning tasks manually.
              </small>

              {/* Default Price Setting */}
              <div className="mt-4">
                <h5 className="form-label">Default Cleaning Price</h5>
                <div className={`input-group ${styles.inputGroupLimited}`}>
                  <input
                    type="text"
                    name="default_job_price"
                    inputMode="decimal" // Opens numeric keypad on mobile
                    value={defaultJobPriceInput}
                    onChange={(e) => {
                      const value = e.target.value;
                      setDefaultJobPriceInput(value);
                    }}
                    onBlur={(e) => {
                      let value = e.target.value.replace(',', '.'); // Standardize to dot for decimal

                      // Check if input has more than one decimal separator or is not a valid number
                      if (!/^[0-9]*\.?[0-9]*$/.test(value) || isNaN(parseFloat(value))) {
                        alert('Please enter a valid number (e.g., 10.50 or 10,50). Only digits and a single decimal separator are allowed.');

                        // Revert to the previous valid value
                        setDefaultJobPriceInput(lastValidPriceInput); // Revert to the last valid input
                        return;
                      }

                      // Convert to cents if valid
                      const convertedCents = Math.round(parseFloat(value) * 100);

                      setEditData((prevData) => ({
                        ...prevData,
                        default_job_price_cents: convertedCents, // Update the state with the correct cents value
                      }));

                      // Update the input with formatted value and set the last valid input
                      setDefaultJobPriceInput((convertedCents / 100).toFixed(2));
                      setLastValidPriceInput((convertedCents / 100).toFixed(2)); // Store the last valid input
                    }}
                    className="form-control"
                    placeholder="Set default price for cleaning jobs"
                  />
                  <select
                    name="default_job_price_currency"
                    value={editData.default_job_price_currency}
                    onChange={handleInputChange}
                    className="form-select"
                    style={{ width: '70px' }}
                  >
                    {[
                      'EUR', 'USD', 'GBP', 'JPY', 'AUD', 'CAD', 'CHF', 'CNY', 'HKD', 'NZD', 'SEK',
                      'KRW', 'SGD', 'NOK', 'MXN', 'INR', 'RUB', 'ZAR', 'TRY', 'BRL', 'TWD', 'DKK',
                      'PLN', 'THB', 'IDR'
                    ].sort().map(code => (
                      <option key={code} value={code}>
                        {`${getSymbolFromCurrency(code)} (${code})`}
                      </option>
                    ))}
                  </select>
                </div>
                <small className="text-muted">
                  Enter the default price using a numeric value (e.g., 10.50). This is the base price for cleaning jobs at this property. It will apply to all cleaners unless overridden with a specific price.
                </small>
              </div>

              {/* Cleaner-Specific Pricing */}
              {/* <div className="mt-4">
                <h5 className="form-label">Cleaner-Specific Pricing</h5>
                <Select
                  options={assignedCleaners.map((cleaner) => ({
                    value: cleaner.id,
                    label: `${cleaner.first_name} ${cleaner.last_name}`,
                    cleaner,
                  }))}
                  onChange={(selectedOption) => {
                    setSelectedCleaner(selectedOption.cleaner);
                  }}
                  value={selectedCleaner}
                  className="basic-multi-select mb-3"
                  classNamePrefix="select"
                  placeholder="Select Cleaner to Set Custom Price"
                />
                {selectedCleaner && (
                  <input
                    type="number"
                    name={`price_${selectedCleaner.id}`}
                    value={editData[`price_${selectedCleaner.id}`] || ''}
                    onChange={handleInputChange}
                    className="form-control mt-2"
                    placeholder={`Set custom price for ${selectedCleaner.first_name} ${selectedCleaner.last_name}`}
                  />
                )}
              </div> */}
              {/* Cleaner Assignment Rules */}
              {/* <div className="mt-4">
                <h5 className="form-label">Cleaner Assignment Rules</h5>
                <label className="form-label">Primary Cleaner</label>
                <Select
                  options={assignedCleaners.map((cleaner) => ({
                    value: cleaner.id,
                    label: `${cleaner.first_name} ${cleaner.last_name}`,
                  }))}
                  onChange={(selectedOption) => setEditData({ ...editData, primaryCleaner: selectedOption.value })}
                  value={assignedCleaners.find((c) => c.id === editData.primaryCleaner) || null}
                  className="basic-select mb-3"
                  classNamePrefix="select"
                  placeholder="Select Primary Cleaner"
                />

                <label className="form-label">Fallback Cleaners</label>
                <Select
                  isMulti
                  options={assignedCleaners.map((cleaner) => ({
                    value: cleaner.id,
                    label: `${cleaner.first_name} ${cleaner.last_name}`,
                  }))}
                  onChange={(selectedOptions) => setEditData({ ...editData, fallbackCleaners: selectedOptions.map((opt) => opt.value) })}
                  value={assignedCleaners.filter((c) => editData.fallbackCleaners?.includes(c.id))}
                  className="basic-multi-select mb-3"
                  classNamePrefix="select"
                  placeholder="Select Fallback Cleaners"
                />

                <label className="form-label">Response Timeframe (hours)</label>
                <input
                  type="number"
                  name="response_timeframe"
                  value={editData.response_timeframe || ''}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Set response timeframe in hours"
                />
                <small className="text-muted">
                  Set the maximum time (in hours) for the primary cleaner to respond before the job is reassigned to the next cleaner.
                </small>
              </div> */}

              {/* Job Posting Automation */}
              <div className="mt-4">
                <h5 className="form-label">Job Posting Automation</h5>
                <div className="form-check">
                  <input
                    type="checkbox"
                    name="auto_post_jobs"
                    checked={airbnbImport?.auto_post || false}
                    onChange={(e) => setAirbnbImport({ ...airbnbImport, auto_post: e.target.checked })}
                    className="form-check-input"
                  />
                  <label className="form-check-label">Enable Automatic Job Posting</label>
                </div>
                <small className="text-muted">
                  Automatically post jobs to cleaners based on your rules and calendar events.
                </small>
              </div>

              {/* Calendar Import Settings */}
              <div className="mt-4">
                <h5 className="form-label">Import Calendar Settings</h5>
                <label className="form-label">Import Calendar for:</label>
                <select
                  name="weeksToImport"
                  value={airbnbImport?.import_weeks}
                  onChange={(e) => setAirbnbImport({ ...airbnbImport, import_weeks: e.target.value })}
                  className="form-control"
                >
                  <option value={1}>1 Week</option>
                  <option value={2}>2 Weeks</option>
                  <option value={4}>4 Weeks</option>
                  <option value={8}>8 Weeks</option>
                </select>
                <small className="text-muted">
                  Select how many weeks ahead you would like to import your calendar. This setting determines how far in advance cleaning jobs will be scheduled.
                </small>
              </div>
              <div className="d-flex justify-content-start">
                <button className="btn btn-primary mt-2" onClick={importCalendar}>
                  Import Calendar
                </button>
              </div>

              {/* Centered Close Button at the Bottom */}
              <div className="d-flex justify-content-center mt-auto">
                <button className="btn btn-secondary" onClick={closeSection}>
                  Close
                </button>
              </div>

            </>
          )}

          {/* More Options Section */}
          {activeSection === 'More Options' && (
            <>
              <p className={styles.cardContent}>
                Deleting a property will permanently remove it from your account. This action cannot be undone.
              </p>
              <div className="d-flex justify-content-start mt-3">
                <button className="btn btn-danger" onClick={handleDeleteProperty}>
                  Delete Property
                </button>
                <button className="btn btn-secondary ms-2" onClick={closeSection}>
                  Cancel
                </button>
              </div>
            </>
          )}

          {/* Default Price Section */}
          {activeSection === 'Default Price' && (
            <>
              <div className="mt-4">
                <h5 className="form-label">Default Cleaning Price</h5>
                <div className={`input-group ${styles.inputGroupLimited}`}>
                  <input
                    type="text"
                    name="default_job_price"
                    inputMode="decimal"
                    value={defaultJobPriceInput}
                    onChange={(e) => {
                      const value = e.target.value;
                      setDefaultJobPriceInput(value);
                    }}
                    onBlur={(e) => {
                      let value = e.target.value.replace(',', '.');

                      if (!/^[0-9]*\.?[0-9]*$/.test(value) || isNaN(parseFloat(value))) {
                        alert('Please enter a valid number (e.g., 10.50 or 10,50).');
                        setDefaultJobPriceInput(lastValidPriceInput);
                        return;
                      }

                      const convertedCents = Math.round(parseFloat(value) * 100);

                      setEditData((prevData) => ({
                        ...prevData,
                        default_job_price_cents: convertedCents,
                      }));

                      setDefaultJobPriceInput((convertedCents / 100).toFixed(2));
                      setLastValidPriceInput((convertedCents / 100).toFixed(2));
                    }}
                    className="form-control"
                    placeholder="Set default price for cleaning jobs"
                  />
                  <select
                    name="default_job_price_currency"
                    value={editData.default_job_price_currency}
                    onChange={handleInputChange}
                    className="form-select"
                    style={{ maxWidth: '120px' }}
                  >
                    {[
                      'EUR', 'USD', 'GBP', 'JPY', 'AUD', 'CAD', 'CHF', 'CNY', 'HKD', 'NZD', 'SEK',
                      'KRW', 'SGD', 'NOK', 'MXN', 'INR', 'RUB', 'ZAR', 'TRY', 'BRL', 'TWD', 'DKK',
                      'PLN', 'THB', 'IDR'
                    ].sort().map(code => (
                      <option key={code} value={code}>
                        {`${getSymbolFromCurrency(code)} (${code})`}
                      </option>
                    ))}
                  </select>
                </div>
                <small className="text-muted">
                  This is the default price that will be applied to new cleaning jobs for this property.
                  You can still adjust the price for individual jobs when creating them.
                </small>
              </div>
            </>
          )}
        </div>

        {/* Save and Cancel (Close) Buttons for Other Sections */}
        {activeSection !== 'Update from Airbnb' && activeSection !== 'Automate' && (
          <div className={styles.modalFooter}>
            <button className="btn btn-secondary" onClick={closeSection}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={saveChanges}>
              Save
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
}