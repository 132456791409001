import React, { useContext, useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import logo from '../../../assets/images/logonavbar.png';
import { UserContext } from '../../../context/UserContext';
import MobileNavbar from './MobileNavbar';
import { FaChartBar, FaMoneyBill, FaUser, FaSignOutAlt, FaExchangeAlt } from 'react-icons/fa';
import { switchUserRole } from '../../../api/usersApi';

export default function Header() {
  const { user, logout, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = React.useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!user) {
      setIsMobile(false);
    } else {
      setIsMobile(window.innerWidth <= 768);
    }
  }, [user]);

  // Handle clicks outside the dropdown
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }
    
    // Only add the event listener when the dropdown is open
    if (isDropdownOpen) {
      // Use capture phase to ensure we catch the event before it's stopped
      document.addEventListener('mousedown', handleClickOutside, true);
      document.addEventListener('touchstart', handleClickOutside, true);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
      document.removeEventListener('touchstart', handleClickOutside, true);
    };
  }, [isDropdownOpen]);

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  if (user && isMobile) {
    return <MobileNavbar />;
  }

  return (
    <header className="h-20 flex items-center shadow-sm bg-white">
      <nav className="w-full px-4 flex justify-between items-center">
        {/* Logo */}
        <a href="/" className="flex items-center">
          <img src={logo} alt="ConnectClean" className="w-10 h-10" />
        </a>

        {/* Navigation Links */}
        <div className="flex items-center">
          <ul className="flex space-x-1">
            {user ? (
              <>
                <li className="mx-2">
                  <NavLink
                    to="/calendar"
                    className={({ isActive }) => 
                      `px-5 py-2 rounded-md font-semibold text-gray-700 hover:bg-gray-100 transition-colors flex items-center ${isActive ? 'border-b-2 border-primary bg-gray-100 text-gray-900 font-bold' : ''}`
                    }
                  >
                    Calendar
                  </NavLink>
                </li>
                <li className="mx-2">
                  <NavLink
                    to="/properties"
                    className={({ isActive }) => 
                      `px-5 py-2 rounded-md font-semibold text-gray-700 hover:bg-gray-100 transition-colors flex items-center ${isActive ? 'border-b-2 border-primary bg-gray-100 text-gray-900 font-bold' : ''}`
                    }
                  >
                    My Properties
                  </NavLink>
                </li>
                <li className="mx-2">
                  <NavLink
                    to="/dashboard"
                    className={({ isActive }) => 
                      `px-5 py-2 rounded-md font-semibold text-gray-700 hover:bg-gray-100 transition-colors flex items-center ${isActive ? 'border-b-2 border-primary bg-gray-100 text-gray-900 font-bold' : ''}`
                    }
                  >
                    <FaChartBar className="mr-2" />
                    Dashboard
                  </NavLink>
                </li>
              </>
            ) : (
              <li className="mx-2">
                <NavLink 
                  to="/login" 
                  className={({ isActive }) => 
                    `px-5 py-2 rounded-md font-semibold text-gray-700 hover:bg-gray-100 transition-colors flex items-center ${isActive ? 'border-b-2 border-primary bg-gray-100 text-gray-900 font-bold' : ''}`
                  }
                >
                  Login
                </NavLink>
              </li>
            )}
          </ul>
        </div>

        {/* User Profile Dropdown */}
        {user && (
          <div className="relative" ref={dropdownRef}>
            <button 
              onClick={(e) => {
                e.stopPropagation(); // Prevent event from bubbling
                setIsDropdownOpen(!isDropdownOpen);
              }}
              className="flex items-center gap-3 px-3 py-1 border border-gray-300 rounded-full hover:shadow-md transition-all"
            >
              <div className="flex items-center gap-3">
                <div className="w-4 h-0.5 bg-gray-500 relative before:content-[''] before:absolute before:w-4 before:h-0.5 before:bg-gray-500 before:-top-1.5 before:left-0 after:content-[''] after:absolute after:w-4 after:h-0.5 after:bg-gray-500 after:top-1.5 after:left-0"></div>
                <div className="w-8 h-8 bg-gray-500 rounded-full flex items-center justify-center text-white">
                  <FaUser className="w-4 h-4" />
                </div>
              </div>
            </button>
            
            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg overflow-hidden z-10 border border-gray-200">
                <NavLink 
                  to={`/user/${user.id}`}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                >
                  <FaUser className="mr-2 text-gray-600" />
                  User Profile
                </NavLink>
                
                <button 
                  className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                  onClick={async () => {
                    try {
                      const response = await switchUserRole(user.current_role);
                      setUser(response.user);
                      setIsDropdownOpen(false);
                    } catch (error) {
                      console.error('Error switching role:', error);
                    }
                  }}
                >
                  <FaExchangeAlt className="mr-2 text-gray-600" />  
                  Switch to {user.current_role === 'manager' ? 'cleaner' : 'manager'} mode
                </button>
                
                <button 
                  className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                  onClick={() => {
                    handleLogout();
                    setIsDropdownOpen(false);
                  }}
                >
                  <FaSignOutAlt className="mr-2 text-gray-600" />
                  Logout
                </button>
              </div>
            )}
          </div>
        )}
      </nav>
    </header>
  );
}
