import React, { useState, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import WizardProgress from './WizardComponents/WizardProgress';
import WizardNavigation from './WizardComponents/WizardNavigation';

// Step Components
import IntroductionStep from './WizardComponents/IntroductionStep';
import PropertyBasicsStep from './WizardComponents/PropertyBasicsStep';
import BudgetStep from './WizardComponents/BudgetStep';
import ScheduleStep from './WizardComponents/ScheduleStep';
import TeamStep from './WizardComponents/TeamStep';
import CalendarStep from './WizardComponents/CalendarStep';
import OptionalDetailsStep from './WizardComponents/OptionalDetailsStep';
import ReviewStep from './WizardComponents/ReviewStep';

// Create contexts
export const FormDataContext = createContext();
export const WizardContext = createContext();

export default function PropertyFormWizard() {
  const navigate = useNavigate();
  
  // Define steps
  const steps = [
    { id: 'introduction', title: 'Introduction', component: IntroductionStep, required: true },
    { id: 'basics', title: 'Property Basics', component: PropertyBasicsStep, required: true },
    { id: 'budget', title: 'Budget', component: BudgetStep, required: true },
    { id: 'schedule', title: 'Cleaning Schedule', component: ScheduleStep, required: true },
    { id: 'team', title: 'Team Assignment', component: TeamStep, required: true },
    { id: 'calendar', title: 'Calendar Integration', component: CalendarStep, required: false },
    { id: 'details', title: 'Property Details', component: OptionalDetailsStep, required: false },
    { id: 'review', title: 'Review & Submit', component: ReviewStep, required: true },
  ];

  // State for the current step index
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  
  // Initial form data
  const [formData, setFormData] = useState({
    // Manual entry mode
    isManualEntry: true,
    
    // Basic property information
    title: '',
    address: '',
    photo: null,
    
    // Budget information
    default_job_price: '',
    default_job_price_currency: 'EUR',
    
    // Schedule information
    default_cleaning_from: '12:00',
    default_cleaning_until: '16:00',
    
    // Team information
    managers: [],
    cleaners: [],
    
    // Calendar information
    ical_url: '',
    import_weeks: 2,
    auto_post: false,
    
    // Optional details
    square_meters: '',
    number_rooms: '',
    number_bathrooms: '',
    has_cleaning_supplies: false,
    description: '',
  });

  // Current step
  const currentStep = steps[currentStepIndex];
  const CurrentStepComponent = currentStep.component;
  
  // Navigation functions
  const goToNextStep = () => {
    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  };
  
  const goToPreviousStep = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };
  
  const skipStep = () => {
    if (currentStepIndex < steps.length - 1 && !currentStep.required) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  };
  
  const goToStep = (index) => {
    if (index >= 0 && index < steps.length) {
      setCurrentStepIndex(index);
    }
  };

  // Wizard context value
  const wizardContextValue = {
    steps,
    currentStepIndex,
    goToNextStep,
    goToPreviousStep,
    skipStep,
    goToStep,
    isLastStep: currentStepIndex === steps.length - 1,
    isFirstStep: currentStepIndex === 0
  };

  // Form data context value
  const formDataContextValue = {
    formData,
    updateFormData: (newData) => {
      setFormData(prevData => ({ ...prevData, ...newData }));
    }
  };

  return (
    <WizardContext.Provider value={wizardContextValue}>
      <FormDataContext.Provider value={formDataContextValue}>
        <div className="container mx-auto px-4 py-8">
          <WizardProgress />
          
          <div className="bg-white rounded-lg shadow-md p-6 mb-6">
            <h1 className="text-h3 md:text-h2 text-center mb-6">{currentStep.title}</h1>
            
            <CurrentStepComponent />
            
            <WizardNavigation />
          </div>
        </div>
      </FormDataContext.Provider>
    </WizardContext.Provider>
  );
}
