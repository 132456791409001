import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { UserContext } from 'context/UserContext';
import { 
  FaCalendarAlt, 
  FaHome, 
  FaChartBar, 
  FaBars, 
  FaSignOutAlt,
  FaExchangeAlt,
  FaUser
} from 'react-icons/fa';
import { switchUserRole } from 'api/usersApi';

// Check if Netlify deploy is working.
export default function MobileNavbar() {
  const { user, logout, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [showFinancialMenu, setShowFinancialMenu] = useState(false);
  const menuRef = useRef(null);
  const financialMenuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMenu && menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
      if (showFinancialMenu && financialMenuRef.current && !financialMenuRef.current.contains(event.target)) {
        setShowFinancialMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu, showFinancialMenu]);

  const handleLogout = async () => {
    await logout();
    navigate('/');
    setShowMenu(false);
  };

  const toggleMenu = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
    setShowFinancialMenu(false);
  };

  const toggleFinancialMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowFinancialMenu(!showFinancialMenu);
    setShowMenu(false);
  };

  return (
    <div className="relative">
      <nav className="fixed bottom-0 left-0 right-0 bg-white flex justify-around items-center p-3 shadow-[0_-2px_10px_rgba(0,0,0,0.1)] z-50">
        <NavLink 
          to="/calendar" 
          className={({ isActive }) => 
            `flex flex-col items-center text-xs ${isActive ? 'text-primary' : 'text-gray-500'}`
          }
        >
          <FaCalendarAlt className="text-xl mb-1" />
          <span>Calendar</span>
        </NavLink>
        
        <NavLink 
          to="/properties" 
          className={({ isActive }) => 
            `flex flex-col items-center text-xs ${isActive ? 'text-primary' : 'text-gray-500'}`
          }
        >
          <FaHome className="text-xl mb-1" />
          <span>Properties</span>
        </NavLink>
        
        {user && (
          <NavLink 
            to="/dashboard" 
            className={({ isActive }) => 
              `flex flex-col items-center text-xs ${isActive ? 'text-primary' : 'text-gray-500'}`
            }
            onClick={() => setShowFinancialMenu(false)}
          >
            <FaChartBar className="text-xl mb-1" />
            <span>Dashboard</span>
          </NavLink>
        )}
        
        <button 
          onClick={toggleMenu} 
          className="flex flex-col items-center text-xs text-gray-500 bg-transparent border-none min-w-[48px]"
        >
          <FaBars className="text-xl mb-1" />
          <span>Menu</span>
        </button>
      </nav>

      {showMenu && (
        <div 
          ref={menuRef} 
          className="fixed bottom-[60px] left-0 right-0 bg-white shadow-[0_-2px_10px_rgba(0,0,0,0.1)] p-5 z-40 animate-[slideUp_0.2s_ease-out]"
        >
          <NavLink 
            to={`/user/${user.id}`} 
            className="flex items-center py-2.5 text-gray-800 hover:bg-gray-100 w-full"
          >
            <FaUser className="mr-4 text-lg" />
            <span>User Profile</span>
          </NavLink>
          
          <button 
            className="flex items-center py-2.5 text-gray-800 hover:bg-gray-100 w-full text-left bg-transparent border-none"
            onClick={async () => {
              try {
                const response = await switchUserRole(user.current_role);
                setUser(response.user);
                setShowMenu(false);
              } catch (error) {
                console.error('Error switching role:', error);
              }
            }}
          >
            <FaExchangeAlt className="mr-4 text-lg" />
            <span>Switch to {user.current_role === 'manager' ? 'cleaner' : 'manager'} mode</span>
          </button>
          
          <button 
            onClick={handleLogout} 
            className="flex items-center py-2.5 text-gray-800 hover:bg-gray-100 w-full text-left bg-transparent border-none"
          >
            <FaSignOutAlt className="mr-4 text-lg" />
            <span>Logout</span>
          </button>
        </div>
      )}
    </div>
  );
}
