import React from 'react';

/**
 * FileUpload component for handling file uploads with preview functionality
 * @param {Object} props - Component props
 * @param {string} props.label - Input label
 * @param {string} props.id - Input ID
 * @param {string} props.name - Input name
 * @param {Function} props.onChange - Function called when file is selected
 * @param {string} props.accept - Accepted file types
 * @param {string} props.preview - Preview URL
 * @param {string} props.previewAlt - Alt text for preview image
 * @param {string} props.className - Additional CSS classes
 */
const FileUpload = ({
  label,
  id,
  name,
  onChange,
  accept,
  preview,
  previewAlt = 'File preview',
  className = '',
  ...props
}) => {
  const handleChange = (e) => {
    const file = e.target.files[0];
    if (onChange && file) {
      onChange(file);
    }
  };

  return (
    <div className={className}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <input
        type="file"
        id={id}
        name={name}
        onChange={handleChange}
        accept={accept}
        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
        {...props}
      />
      
      {preview && (
        <div className="mt-2">
          <img 
            src={preview} 
            alt={previewAlt} 
            className="max-w-full max-h-[200px] rounded-md" 
          />
        </div>
      )}
    </div>
  );
};

export default FileUpload;