import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { fetchProperties } from '../../../api/propertiesApi';
import { Link, useNavigate } from 'react-router-dom';
import styles from './PropertiesIndex.module.css';
import { BiTime } from 'react-icons/bi';
import { IoLocationOutline } from 'react-icons/io5';
import { FaEuroSign, FaDollarSign } from 'react-icons/fa';
import { TbCurrencyReal } from 'react-icons/tb';
import getSymbolFromCurrency from 'currency-symbol-map';
import Button from '../../../components/UI/Button/Button';

const PropertiesIndex = () => {
  const { user } = useContext(UserContext);
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getProperties = async () => {
      setIsLoading(true);
      try {
        const data = await fetchProperties();
        setProperties(data);
      } catch (error) {
        console.error('Failed to fetch properties', error);
      } finally {
        setIsLoading(false);
      }
    };

    getProperties();
  }, []);

  console.log(properties);

  const formatPrice = (cents, currency) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: currency || 'EUR'
    }).format(cents / 100);
  };

  const getCurrencyIcon = (currency) => {
    // Special cases for currencies with specific icons
    switch (currency) {
      case 'EUR':
        return <FaEuroSign className={styles.icon} />;
      case 'USD':
        return <FaDollarSign className={styles.icon} />;
      case 'BRL':
        return <TbCurrencyReal className={styles.icon} />;
      default:
        // For other currencies, display the currency symbol as text
        const symbol = getSymbolFromCurrency(currency);
        return <span className={styles.icon}>{symbol}</span>;
    }
  };

  const PropertyCard = ({ property }) => {
    const isManager = user.current_role === 'manager';
    
    return (
      <Link 
        to={`/properties/${property.id}`}
        className={styles.cardLink}
      >
        <div className={styles.card}>
          <div className={styles.imageContainer}>
            <img 
              src={property.photo_url} 
              alt={property.title}
              className={styles.propertyImage}
            />
          </div>
          <div className={styles.content}>
            <h2 className={styles.title}>{property.title}</h2>
            
            <div className={styles.infoItem}>
              <IoLocationOutline className={styles.icon} />
              <p className={styles.address}>{property.address}</p>
            </div>
            
            <div className={styles.infoItem}>
              <BiTime className={styles.icon} />
              <div className={styles.schedule}>
                <span className={styles.label}>Cleaning hours:</span>
                <span className={styles.time}>
                  {property.default_cleaning_from} - {property.default_cleaning_until}
                </span>
              </div>
            </div>
            
            <div className={styles.infoItem}>
              {getCurrencyIcon(property.default_job_price_currency)}
              <div className={styles.pricing}>
                <span className={styles.label}>
                  {isManager ? 'Price per cleaning:' : 'Earnings per cleaning:'}
                </span>
                <span className={styles.amount}>
                  {formatPrice(
                    property.default_job_price_cents,
                    property.default_job_price_currency
                  )}
                </span>
              </div>
            </div>
            
            {property.description && (
              <div className={styles.descriptionContainer}>
                <p className={styles.description}>{property.description}</p>
              </div>
            )}
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div className={styles.container}>
      <div className="flex justify-between items-center mb-6">
        <h1 className={styles.pageTitle}>
          {user.current_role === 'manager' ? 'Your Properties' : 'Properties you are cleaning'}
        </h1>
        
        {user.current_role === 'manager' && (
          <Button 
            variant="primary" 
            onClick={() => navigate('/properties/new')}
            className="ml-auto"
          >
            Create Property
          </Button>
        )}
      </div>
      
      {properties.length > 0 ? (
        <div className={styles.grid}>
          {properties.map(property => (
            <PropertyCard key={property.id} property={property} />
          ))}
        </div>
      ) : (
        !isLoading && (
          <div className={styles.noPropertiesContainer}>
            <div className={styles.noPropertiesContent}>
              <h2>No Properties Found</h2>
              {user.current_role === 'manager' ? (
                <>
                  <p>You don't have any properties yet. Create a property to start adding cleaning jobs.</p>
                  <Button 
                    variant="primary" 
                    onClick={() => navigate('/properties/new')}
                  >
                    Create Property
                  </Button>
                </>
              ) : (
                <p>You haven't been assigned to any properties yet. Please contact your property manager to add you to their properties.</p>
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default PropertiesIndex;
