import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaFileDownload, FaCircle } from 'react-icons/fa';
import styles from './Payouts.module.css';
import { format, parse } from 'date-fns';
import { UserContext } from '../../../../../context/UserContext';
import PayoutDetails from './PayoutDetails';
import Button from '../../../../../components/UI/Button/Button';

export default function Payouts({ payoutsData }) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [selectedPayout, setSelectedPayout] = useState(null);

  const formatCurrency = (amount, currency = 'USD') => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(amount);
  };

  const groupPayoutsByCurrency = (payouts) => {
    return payouts.reduce((acc, payout) => {
      const currency = payout.total_amount_currency;
      if (!acc[currency]) {
        acc[currency] = {
          totalCents: 0,
          jobsCount: 0
        };
      }
      acc[currency].totalCents += payout.total_amount_cents;
      acc[currency].jobsCount += payout.jobs_count;
      return acc;
    }, {});
  };

  return (
    <div className={styles.container}>
      <div className="flex justify-between items-center mb-6 px-4">
        <h1 className="text-2xl font-semibold">Payouts</h1>
        {user?.current_role === 'manager' && (
          <div className="ml-4">
            <Button 
              variant="primary"
              onClick={() => navigate('/payouts/new')}
              className="flex items-center gap-2 px-4 py-2"
            >
              <FaPlus /> Create Payout
            </Button>
          </div>
        )}
      </div>

      <div className={styles.statsCards}>
        <div className={styles.statCard}>
          <h3>Total Payouts</h3>
          {Object.entries(groupPayoutsByCurrency(payoutsData.payouts)).length > 0 ? (
            Object.entries(groupPayoutsByCurrency(payoutsData.payouts)).map(([currency, data]) => (
              <p key={currency}>
                {formatCurrency(data.totalCents / 100, currency)}
              </p>
            ))
          ) : (
            <p>0</p>
          )}
          <span>Including jobs and adjustments</span>
        </div>
        <div className={styles.statCard}>
          <h3>Average Price per job</h3>
          {Object.entries(groupPayoutsByCurrency(payoutsData.payouts)).length > 0 ? (
            Object.entries(groupPayoutsByCurrency(payoutsData.payouts)).map(([currency, data]) => (
              <p key={currency}>
                {formatCurrency(data.jobsCount > 0 ? data.totalCents / data.jobsCount / 100 : 0, currency)}
              </p>
            ))
          ) : (
            <p>0</p>
          )}
          <span>This period</span>
        </div>
      </div>

      <div className={styles.tableContainer}>
        <div className={styles.desktopView}>
          <table className={styles.payoutsTable}>
            <thead>
              <tr>
                <th>{user.current_role === 'cleaner' ? 'Manager' : 'Cleaner'}</th>
                <th>Period</th>
                <th>Amount</th>
                <th>Jobs</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {payoutsData.payouts.map((payout) => (
                <tr 
                  key={payout.id}
                  onClick={() => setSelectedPayout(payout.id)}
                  className={styles.tableRow}
                >
                  <td>{user.current_role === 'cleaner' 
                    ? `${payout.manager.first_name} ${payout.manager.last_name}`
                    : `${payout.cleaner.first_name} ${payout.cleaner.last_name}`
                  }</td>
                  <td>{format(new Date(payout.date), 'MMMM yyyy')}</td>
                  <td>{formatCurrency(payout.total_amount_cents / 100, payout.total_amount_currency)}</td>
                  <td>{payout.jobs_count}</td>
                  <td>{format(new Date(payout.date), 'yyyy-MM-dd')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.mobileView}>
          {payoutsData.payouts.map((payout) => (
            <div 
              key={payout.id}
              onClick={() => setSelectedPayout(payout.id)}
              className={styles.mobileTableRow}
            >
              <div className={styles.mobileTableHeader}>
                <span>{user.current_role === 'cleaner'
                  ? `${payout.manager.first_name} ${payout.manager.last_name}`
                  : `${payout.cleaner.first_name} ${payout.cleaner.last_name}`
                }</span>
                <span>{formatCurrency(payout.total_amount_cents / 100, payout.total_amount_currency)}</span>
              </div>
              <div className={styles.mobileTableDetails}>
                <div>
                  <label>Jobs:</label>
                  <span>{payout.jobs_count}</span>
                </div>
                <div>
                  <label>Period:</label>
                  <span>{format(new Date(payout.date), 'MMMM yyyy')}</span>
                </div>
                <div>
                  <label>Date:</label>
                  <span>{format(new Date(payout.date), 'yyyy-MM-dd')}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedPayout && (
        <div className={styles.modalOverlay} onClick={(e) => {
          if (e.target === e.currentTarget) setSelectedPayout(null);
        }}>
          <PayoutDetails 
            payoutId={selectedPayout} 
            onClose={() => setSelectedPayout(null)} 
          />
        </div>
      )}
    </div>
  );
}
