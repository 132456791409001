import React from 'react';

/**
 * FormCheckbox component for checkbox inputs with labels and help text
 * @param {Object} props - Component props
 * @param {string} props.id - Checkbox ID
 * @param {string} props.name - Checkbox name
 * @param {boolean} props.checked - Whether the checkbox is checked
 * @param {Function} props.onChange - Function called when checkbox changes
 * @param {string} props.label - Checkbox label
 * @param {string} props.error - Error message
 * @param {string} props.helpText - Help text
 * @param {boolean} props.required - Whether the checkbox is required
 * @param {string} props.className - Additional CSS classes
 */
const FormCheckbox = ({
  id,
  name,
  checked,
  onChange,
  label,
  error,
  helpText,
  required = false,
  className = '',
  ...props
}) => {
  return (
    <div className={className}>
      <div className="flex items-start">
        <div className="relative flex items-center h-5">
          <input
            type="checkbox"
            id={id}
            name={name}
            checked={checked}
            onChange={onChange}
            required={required}
            className="appearance-none h-4 w-4 border border-gray-300 rounded 
                      checked:bg-primary checked:border-primary 
                      hover:border-primary-dark focus:outline-none 
                      focus:ring-2 focus:ring-primary-light focus:ring-offset-0"
            {...props}
          />
          {checked && (
            <div className="absolute inset-0 flex items-center justify-center pointer-events-none text-white">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            </div>
          )}
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={id} className="font-medium text-gray-700">
            {label}
          </label>
        </div>
      </div>
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
      {helpText && (
        <p className="mt-1 text-sm text-gray-500 ml-7">{helpText}</p>
      )}
    </div>
  );
};

export default FormCheckbox;
