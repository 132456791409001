import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProperty } from '../../../api/propertiesApi';
import { UserContext } from '../../../context/UserContext';
import ManagerPropertyCard from './ManagerPropertyCard';
import CleanerPropertyCard from './CleanerPropertyCard';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/UI/Button/Button';

export default function PropertyDetails() {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const [property, setProperty] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadProperty = async () => {
      const fetchedProperty = await fetchProperty(id);
      setProperty(fetchedProperty);
    };
    loadProperty();
  }, [id]);

  if (!property) return <div>Loading...</div>;

  return (
    <div className="container-fluid p-0">
      <div className="property-content">
        {user.current_role === 'manager' ? (
          <ManagerPropertyCard property={property} />
        ) : (
          <CleanerPropertyCard property={property} />
        )}
      </div>
      <div className="px-4 md:px-6 lg:px-8 mt-2 mb-4">
        <Button 
          onClick={() => navigate('/properties')} 
          variant="outline"
          size="md"
        >
          Back to Properties
        </Button>
      </div>
    </div>
  );
}
