import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import { fetchProperties, fetchProperty } from 'api/propertiesApi';
import { createJob } from 'api/jobsApi';
import styles from './JobForm.module.css';
import JobFormFields from './JobFormFields';

export default function JobForm() {
  const [properties, setProperties] = useState([]);
  const [formData, setFormData] = useState({
    property_id: '',
    date_of_job: '',
    revenue: '',
    revenue_currency: '',
    price: '',
    price_currency: '',
    cleaning_from: '',
    cleaning_until: '',
    description: '',
    job_status: 'open',
    post_all: false
  });
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [cleaners, setCleaners] = useState([]);

  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const loadProperties = async () => {
      try {
        const properties = await fetchProperties();
        setProperties(properties);
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };
    loadProperties();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePropertySelect = async (e) => {
    const propertyId = e.target.value;
    try {
      const property = await fetchProperty(propertyId);
      setSelectedProperty(property);
      setCleaners(property.cleaners || []);
      setFormData(prevState => ({
        ...prevState,
        property_id: propertyId,
        price: property && property.default_job_price_cents != null 
          ? (property.default_job_price_cents / 100).toString() 
          : '',
        price_currency: property?.default_job_price_currency || '',
        cleaning_from: property?.default_cleaning_from || '',
        cleaning_until: property?.default_cleaning_until || '',
        description: property?.description || ''
      }));
    } catch (error) {
      console.error('Error fetching property:', error);
      setFormData(prevState => ({
        ...prevState,
        property_id: propertyId,
        price: '',
        price_currency: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const jobData = {
        ...formData,
        price_cents: Math.round(parseFloat(formData.price) * 100),
        user_id: user.id,
        date_of_job: formData.date_of_job || new Date().toISOString().split('T')[0],
        status: formData.job_status
      };
      delete jobData.price;
      
      const newJob = await createJob(jobData);
      navigate('/calendar');
    } catch (error) {
      console.error('Error creating job:', error);
    }
  };

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.formTitle}>Create New Job</h2>
      <JobFormFields
        formData={formData}
        setFormData={setFormData}
        properties={properties}
        cleaners={cleaners}
        selectedProperty={selectedProperty}
        handleInputChange={handleInputChange}
        handlePropertySelect={handlePropertySelect}
        handleSubmit={handleSubmit}
        user={user}
        formMode="create"
      />
    </div>
  );
}
