import React, { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import styles from './CleanHome.module.css';

export default function CleanHome() {
  const { currentUser } = useContext(UserContext);

  return (
    <div>
      <div className={styles.container}>
        <header className={styles.hero}>
          <div className={styles.heroContent}>
            <h1 className={styles.heroTitle}>Automate Your Airbnb Cleaning Management</h1>
            <p className={styles.heroSubtitle}>Manage your trusted cleaners, automate scheduling, and ensure your properties are always guest-ready</p>
          </div>
        </header>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>Streamline Your Short-Term Rental Turnover</h2>
          <p className={styles.sectionContent}>
            Are you juggling multiple Airbnb  properties? Connectclean eliminates the stress of coordinating cleanings between guest stays. Automatically sync your rental calendars to create cleaning jobs when guests check out, ensuring your property is always ready for the next arrival. Build a dedicated team of trusted cleaners for each property and manage them all in one place.
          </p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>Join Property Teams for Reliable Cleaning Work</h2>
          <p className={styles.sectionContent}>
            As a cleaner on Connectclean, you'll be invited to join specific property teams by owners who value your services. Once added to a property, you'll receive real-time notifications about cleaning jobs, which you can accept with a single click. Track all your assigned jobs, completed cleanings, and payment status through your personalized dashboard—no more confusion about schedules or compensation.
          </p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>Simple, Automated, Reliable</h2>
          <ol className={styles.stepsList}>
            <li className={styles.stepItem}>
              <div className={styles.stepTitle}>Add your Property and Link Your Calendar</div>
              <div>Property owners sync their Airbnb calendars</div>
            </li>
            <li className={styles.stepItem}>
              <div className={styles.stepTitle}>Build Your Team</div>
              <div>Property owners invite trusted cleaners to join their property teams</div>
            </li>
            <li className={styles.stepItem}>
              <div className={styles.stepTitle}>Automatic Scheduling</div>
              <div>Jobs are automatically created when guests check out</div>
            </li>
            <li className={styles.stepItem}>
              <div className={styles.stepTitle}>Real-Time Updates</div>
              <div>Assigned cleaners receive notifications and accept jobs through the app</div>
            </li>
            <li className={styles.stepItem}>
              <div className={styles.stepTitle}>Track Financials</div>
              <div>Both owners and cleaners monitor completed jobs and payment status in one dashboard</div>
            </li>
          </ol>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>Financial Transparency</h2>
          <p className={styles.sectionContent}>
            Keep track of your earnings or cleaning expenses through our intuitive dashboard. Property owners can easily see which jobs are paid and which are still outstanding, while cleaners have a clear record of all completed work and payments received. Everything is organized in one place for straightforward financial management.
          </p>
        </section>

        <section className={styles.ctaSection}>
          <h2 className={styles.ctaTitle}>Currently Free to Use!</h2>
          <p className={styles.ctaDescription}>
            Connectclean is currently free to use while we grow our community. Join today to streamline your property management and focus on what really matters - providing exceptional guest experiences.
          </p>
          {!currentUser && (
            <div className={styles.buttonContainer}>
              <a href="/register" className={styles.button}>Create Your Profile</a>
            </div>
          )}
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>Built by Hosts, for Hosts</h2>
          <p className={styles.sectionContent}>
            Connectclean was born from the real challenges of managing multiple short-term rental properties. We understand the critical role that reliable cleaning plays in guest satisfaction and property success. Our mission is to remove the stress from turnover management, connecting property owners with their trusted cleaning teams through a seamless, automated platform.
          </p>
        </section>

        <section className={styles.contactSection}>
          <h2 className={styles.sectionTitle}>We're Growing With You</h2>
          <p className={styles.sectionContent}>
            We're constantly improving based on your feedback. Have suggestions or feature requests? Contact us at <a href="mailto:ben@connectclean.net" className={styles.contactEmail}>ben@connectclean.net</a> to help shape the future of Connectclean.
          </p>
        </section>
      </div>
    </div>
  );
}
