import React, { useContext } from "react"
import { UserContext } from "../../../../../context/UserContext"
import styles from "./SummaryStats.module.css"
import JobList from './JobList';
import Select from 'react-select';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { reopenJob } from '../../../../../api/jobsApi';
import Button from 'components/UI/Button/Button';


export default function SummaryStats({ jobs, payoutsData, userRole, setJobs }) {
  const { user } = useContext(UserContext);
  const [activeFilter, setActiveFilter] = React.useState('all');
  const [isJobListModalOpen, setIsJobListModalOpen] = React.useState(false);
  const [filterOptions, setFilterOptions] = React.useState({});
  const [filteredJobs, setFilteredJobs] = React.useState(jobs);
  const [paymentFilter, setPaymentFilter] = React.useState('all');

  function getNumberOfJobs(jobsList, filterFn = () => true) {
    return jobsList.filter(filterFn).length;
  }

  function getTotalPrice(jobsList, filterFn = () => true) {
    return jobsList.filter(filterFn).reduce((acc, job) => {
      const currency = job.price_currency;
      const price = job.price_cents / 100;
      if (acc[currency]) {
        acc[currency] += price;
      } else {
        acc[currency] = price;
      }
      return acc;
    }, {});
  }

  function formatTotalPrices(totals) {
    return Object.entries(totals).map(([currency, total], index) => (
      <span key={index} className={styles.price}>
        {total.toFixed(2)} {currency}
      </span>
    ));
  }

  const formatCurrency = (amount, currency = 'USD') => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(amount);
  };

  const groupPayoutsByCurrency = (payouts = []) => {
    return payouts.reduce((acc, payout) => {
      const currency = payout.total_amount_currency;
      if (!acc[currency]) {
        acc[currency] = {
          totalCents: 0,
          count: 0
        };
      }
      acc[currency].totalCents += payout.total_amount_cents;
      acc[currency].count += 1;
      return acc;
    }, {});
  };

  // Get unique cleaners with proper filtering
  const cleaners = [...new Set(jobs
    .filter(job => job.cleaner_first_name || job.cleaner_last_name)
    .map(job => `${job.cleaner_first_name} ${job.cleaner_last_name}`)
    .sort())];

  // Get unique properties
  const properties = [...new Set(jobs
    .filter(job => job.property_title)
    .map(job => job.property_title)
    .sort())];

  // Filter jobs based on active filter
  const getStatusFilteredJobs = (jobs, status) => {
    if (status === 'all') return jobs;
    return jobs.filter(job => job.status === status);
  }

  const statusFilteredJobs = getStatusFilteredJobs(jobs, activeFilter);
  const totalJobs = getNumberOfJobs(statusFilteredJobs);
  const totalPrice = getTotalPrice(statusFilteredJobs);

  const unpaidJobsExist = getNumberOfJobs(filteredJobs, job => !job.paid) > 0;
  const paidJobsExist = getNumberOfJobs(filteredJobs, job => job.paid) > 0;

  const getJobCountByStatus = (status) => {
    if (status === 'all') return filteredJobs.length;
    return filteredJobs.filter(job => job.status === status).length;
  };

  const getAdditionalPayouts = (jobs) => {
    return jobs.reduce((acc, job) => {
      // Check if payout_adjustments exists and is an array
      if (job.payout_adjustments) {
        // Sum up adjustments per currency
        job.payout_adjustments.forEach(adjustment => {
          const currency = adjustment.amount_currency || 'USD';
          const amount = ((adjustment.amount_cents || 0) / 100) / job.number_of_jobs_in_payout;
          
          if (acc[currency]) {
            acc[currency] += amount;
          } else {
            acc[currency] = amount;
          }
        });
      }
      return acc;
    }, {});
  }

  const getAveragePricePerJob = (jobs) => {
    const jobTotals = getTotalPrice(jobs, job => job.paid);
    const additionalPayouts = getAdditionalPayouts(jobs);

    const combinedTotals = combineTotalsPerCurrency(jobTotals, additionalPayouts);
    // Count jobs per currency
    const jobsPerCurrency = jobs.reduce((acc, job) => {
      const currency = job.price_currency;
      if (job.paid) {
        acc[currency] = (acc[currency] || 0) + 1;
      }
      return acc;
    }, {});
    
    // Divide by number of jobs for that specific currency
    return Object.fromEntries(
      Object.entries(combinedTotals).map(([currency, total]) => [
        currency,
        total / (jobsPerCurrency[currency] || 1),
      ])
    );
  }

  const combineTotalsPerCurrency = (jobTotals, additionalPayouts) => {
    const combinedTotals = {};
    for (const currency in jobTotals) {
      combinedTotals[currency] = (combinedTotals[currency] || 0) + jobTotals[currency];
    }
    for (const currency in additionalPayouts) {
      combinedTotals[currency] = (combinedTotals[currency] || 0) + additionalPayouts[currency];
    }
    return combinedTotals;
  }

  // Add click handler for the stats card
  const handleStatsCardClick = () => {
    setIsJobListModalOpen(true);
  };

  // Add close handler for the modal
  const handleCloseModal = (e) => {
    if (e.target.classList.contains(styles.modalOverlay)) {
      setIsJobListModalOpen(false);
    }
  };

  const columns = [
    { label: "Property Name", field: "property_title" },
    { label: userRole === "manager" ? "Cleaner" : "Manager", field: userRole === "manager" ? "cleaner_first_name" : "manager_first_name" },
    { label: "Date", field: "date_of_job" },
    { label: "Price", field: "price_cents" },
  ];

  // Add new state for filter modal
  const [isFilterModalOpen, setIsFilterModalOpen] = React.useState(false);

  // Add toggle function for filter modal
  const toggleFilterModal = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };


  // Function to filter jobs based on status and filterOptions
  const filterJobs = (jobs, status, filters) => {
    return jobs.filter(job => {
      const matchesStatus = status === 'all' || job.status === status;
      const matchesFilters = Object.entries(filters).every(([field, selectedOptions]) => {
        return selectedOptions.some(option => job[field] === option.value);
      });
      return matchesStatus && matchesFilters;
    });
  };

  // Update filteredJobs whenever jobs, filterOptions, or activeFilter change
  React.useEffect(() => {
    setFilteredJobs(filterJobs(jobs, activeFilter, filterOptions));
  }, [jobs, filterOptions, activeFilter]);

  // Handle filter change
  const handleFilterChange = (selectedOptions, field) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      // Clear filter for the specified field
      setFilterOptions((prev) => {
        const updatedFilters = { ...prev };
        delete updatedFilters[field];
        return updatedFilters;
      });
    } else {
      setFilterOptions((prev) => ({
        ...prev,
        [field]: selectedOptions,
      }));
    }
  };

  // Format options for react-select
  const getOptionsForField = (field) => {
    return extractUniqueValues(jobs, field).map(value => ({
      value,
      label: value
    }));
  };

  // Function to extract unique values from a field in the jobs array
  const extractUniqueValues = (jobs, field) => {
  return [...new Set(jobs.map(job => {
    if (field === "price_cents") {
      return (job[field] / 100).toFixed(2);
    } else {
      return job[field];
    }
  }))];
};

  // Function to remove a specific filter
  const removeFilter = (field, value) => {
    setFilterOptions((prev) => {
      const updatedFilters = { ...prev };
      updatedFilters[field] = updatedFilters[field].filter(option => option.value !== value);
      if (updatedFilters[field].length === 0) {
        delete updatedFilters[field];
      }
      return updatedFilters;
    });
  };

  const handleReopenJob = async (jobId) => {
    try {
      const reopenedJob = await reopenJob(jobId);
      // Update jobs and filteredJobs state
      setJobs(prevJobs => prevJobs.map(job => job.id === jobId ? { ...job, status: reopenedJob.status } : job));
      setFilteredJobs(prevFilteredJobs => prevFilteredJobs.map(job => job.id === jobId ? { ...job, status: reopenedJob.status } : job));
      return { status: 'success' };
    } catch (error) {
      console.error('Error reopening job:', error);
      throw error;
    }
  };

  const handleJobDeleted = (deletedJobId) => {
    setJobs(prevJobs => prevJobs.filter(job => job.id !== deletedJobId));
  };

  return (
    <>
      <div className={styles.card}>
        <h1>Jobs</h1>
        
        <div className="flex flex-wrap gap-2 my-4 p-1 bg-gray-100 rounded-lg">
          {['All', 'Completed', 'Accepted', 'Open'].map(filter => (
            <button
              key={filter}
              className={`px-3 py-2 rounded-md font-medium transition-all flex items-center gap-2 ${
                activeFilter === filter.toLowerCase() 
                  ? 'bg-primary-light text-white shadow-sm' 
                  : 'bg-white hover:bg-gray-200 text-gray-700'
              }`}
              onClick={() => setActiveFilter(filter.toLowerCase())}
            >
              {filter}
              <span className={`px-2 py-0.5 text-xs rounded-full min-w-[24px] text-center ${
                activeFilter === filter.toLowerCase()
                  ? 'bg-white text-primary' 
                  : 'bg-gray-200 text-gray-700'
              }`}>
                {getJobCountByStatus(filter.toLowerCase())}
              </span>
            </button>
          ))}
          <button 
            onClick={toggleFilterModal} 
            className="px-3 py-2 rounded-md font-medium transition-all flex items-center gap-2 bg-white hover:bg-gray-200 text-gray-700"
          >
            <FontAwesomeIcon icon={faFilter} /> Filter
          </button>
        </div>

        {/* Active Filters Display */}
        <div className="flex flex-wrap gap-2 mb-4">
          {Object.entries(filterOptions).map(([field, options]) =>
            options.map(option => (
              <div key={`${field}-${option.value}`} className="bg-gray-200 text-gray-800 px-2 py-1 rounded-full text-sm font-medium flex items-center">
                {option.label}
                <span className="ml-1 text-red-600 cursor-pointer hover:text-red-800 font-bold" onClick={() => removeFilter(field, option.value)}>×</span>
              </div>
            ))
          )}
        </div>

        {unpaidJobsExist && paidJobsExist && (
          <div className={styles.inlineSummary}>
            <div 
              className={styles.inlineStat} 
              onClick={() => { handleStatsCardClick(); setPaymentFilter('all'); }}
            >
              <span className={styles.inlineLabel}>Total Jobs:</span>
              <span className={styles.inlineValue}>{totalJobs}</span>
            </div>
            <div 
              className={styles.inlineStat} 
              onClick={() => { handleStatsCardClick(); setPaymentFilter('all'); }}
            >
              <span className={styles.inlineLabel}>Total Value:</span>
              <span className={styles.inlineValue}>
                {formatTotalPrices(combineTotalsPerCurrency(getTotalPrice(filteredJobs), getAdditionalPayouts(filteredJobs)))}
              </span>
            </div>
          </div>
        )}

        {paidJobsExist && (
          <div className={styles.statsCard}>
            <div className={styles.statsCardHeader}>
              <div className={styles.headerIcon}>✅</div>
              <div className={styles.headerContent}>
                <h2>Paid Jobs</h2>
                <div className={styles.totalPrice}>
                  Total: {formatTotalPrices(combineTotalsPerCurrency(getTotalPrice(filteredJobs, job => job.paid), getAdditionalPayouts(filteredJobs)))}
                </div>
              </div>
            </div>
            <div className={styles.overallStats}>
              <div className={`${styles.statBox} ${styles.clickable}`} onClick={() => {handleStatsCardClick(); setPaymentFilter('paid');}}>
                <div className={styles.statIcon}>📊</div>
                <span className={styles.statLabel}># Jobs</span>
                <span className={styles.statValue}>{getNumberOfJobs(filteredJobs, job => job.paid)}</span>
              </div>
              <div className={`${styles.statBox} ${styles.clickable}`} onClick={() => {handleStatsCardClick(); setPaymentFilter('paid');}}>
                <div className={styles.statIcon}>💰</div>
                <span className={styles.statLabel}>Price Jobs</span>
                <span className={styles.statValue}>
                  {formatTotalPrices(getTotalPrice(filteredJobs, job => job.paid))}
                </span>
              </div>
              <div className={`${styles.statBox} ${styles.nonClickable}`}>
                <div className={styles.statIcon}>💵</div>
                <span className={styles.statLabel}>Additional Payouts</span>
                <span className={styles.statValue}>{formatTotalPrices(getAdditionalPayouts(filteredJobs))}</span>
              </div>
              <div className={`${styles.statBox} ${styles.nonClickable}`}>
                <div className={styles.statIcon}>💵</div>
                <span className={styles.statLabel}>Average Price Per Job</span>
                <span className={styles.statValue}>{formatTotalPrices(getAveragePricePerJob(filteredJobs))}</span>
              </div>
            </div>
          </div>
        )}

        {unpaidJobsExist && (
          <div className={styles.statsCard}>
            <div className={styles.statsCardHeader}>
              <div className={styles.headerIcon}>💸</div>
            <h2>Jobs Awaiting Payment</h2>
          </div>
          <div className={styles.overallStats}>
            <div className={`${styles.statBox} ${styles.clickable}`} onClick={() => {handleStatsCardClick(); setPaymentFilter('unpaid');}}>
              <div className={styles.statIcon}>📊</div>
              <span className={styles.statLabel}># Jobs</span>
              <span className={styles.statValue}>{getNumberOfJobs(filteredJobs, job => !job.paid)}</span>
            </div>
            <div className={`${styles.statBox} ${styles.clickable}`} onClick={() => {handleStatsCardClick(); setPaymentFilter('unpaid');}}>
              <div className={styles.statIcon}>💰</div>
              <span className={styles.statLabel}>Price Jobs</span>
              <span className={styles.statValue}>
                {formatTotalPrices(getTotalPrice(filteredJobs, job => !job.paid))}
              </span>
            </div>
            </div>
          </div>
        )}
      </div>

      {/* Filter Modal with Multi-select */}
      {isFilterModalOpen && (
        <div className={styles.filterModalOverlay}>
          <div className={styles.filterModalContent}>
            <h2>Filters</h2>
            <div className={styles.filtersContainer}>
              {columns.map((col) => (
                <div key={col.field} className={styles.filterItem}>
                  <label htmlFor={col.field}>{col.label}:</label>
                  <Select
                    id={col.field}
                    isMulti
                    value={filterOptions[col.field] || []}
                    onChange={(selected) => handleFilterChange(selected, col.field)}
                    options={getOptionsForField(col.field)}
                    placeholder={`Select ${col.label}(s)...`}
                    className={styles.multiSelect}
                    classNamePrefix="select"
                    isClearable={true}
                  />
                </div>
              ))}
            </div>
            <Button 
              variant="outline"  
              onClick={toggleFilterModal}
            >
              Close
            </Button>
          </div>
        </div>
      )}

      {/* Add JobList Modal */}
      {isJobListModalOpen && (
        <div className={styles.modalOverlay} onClick={handleCloseModal}>
          <div className={styles.modalContent}>
            <JobList 
              jobs={filteredJobs} 
              userRole={userRole}
              payoutsData={payoutsData}
              paymentFilter={paymentFilter}
              setPaymentFilter={setPaymentFilter}
              onClose={() => setIsJobListModalOpen(false)}
              handleReopenJob={handleReopenJob}
              onJobDeleted={handleJobDeleted}
            />
          </div>
        </div>
      )}
    </>
  )
}
