import React, { useContext, useState, useEffect } from 'react';
import { FormDataContext } from '../PropertyFormWizard';
import CurrencyInput from 'components/UI/Form/CurrencyInput';

export default function BudgetStep() {
  const { formData, updateFormData } = useContext(FormDataContext);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  
  // Validate form when component mounts and when formData changes
  useEffect(() => {
    validateForm();
    
    // Check if validation was triggered from WizardNavigation
    if (formData._validateBudget) {
      setTouched({ default_job_price: true });
      // Remove the trigger flag
      updateFormData({ _validateBudget: undefined });
    }
  }, [formData]);
  
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.default_job_price) {
      newErrors.default_job_price = 'Default cleaning price is required';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });
    
    // Mark field as touched
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
  };
  
  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
  };
  
  return (
    <div className="space-y-4">
      <p className="mb-4">
        Set the default price for cleaning this property. This will be the starting price for all jobs.
      </p>
      
      <CurrencyInput
        label="Default Cleaning Price *"
        id="default_job_price"
        name="default_job_price"
        value={formData.default_job_price}
        currencyId="default_job_price_currency"
        currencyName="default_job_price_currency"
        currencyValue={formData.default_job_price_currency}
        onChange={handleInputChange}
        onBlur={handleBlur}
        error={touched.default_job_price && errors.default_job_price}
        required
      />
    </div>
  );
}