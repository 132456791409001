import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

/**
 * CurrencyInput component for monetary inputs with currency selection
 * @param {Object} props - Component props
 * @param {string} props.label - Input label
 * @param {string} props.id - Input ID for the amount field
 * @param {string} props.name - Input name for the amount field
 * @param {string} props.value - Input value for the amount
 * @param {string} props.currencyId - ID for the currency select
 * @param {string} props.currencyName - Name for the currency select
 * @param {string} props.currencyValue - Selected currency code
 * @param {Array} props.currencies - Array of currency codes to display
 * @param {Function} props.onChange - Function called when input changes
 * @param {Function} props.onBlur - Function called when input loses focus
 * @param {string} props.error - Error message
 * @param {string} props.helpText - Help text
 * @param {boolean} props.required - Whether the input is required
 * @param {string} props.className - Additional CSS classes
 */
const CurrencyInput = ({
  label,
  id,
  name,
  value,
  currencyId,
  currencyName,
  currencyValue,
  currencies = ['EUR', 'USD', 'GBP', 'JPY', 'AUD', 'CAD', 'CHF', 'CNY'],
  onChange,
  onBlur,
  error,
  helpText,
  required = false,
  className = '',
  ...props
}) => {
  const currencyOptions = currencies.map(code => ({
    label: `${getSymbolFromCurrency(code)} (${code})`,
    value: code
  }));

  return (
    <div className={className}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <div className="flex">
        <input
          type="number"
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          className={`w-full rounded-l-md px-3 py-2 border shadow-sm focus:outline-none focus:ring-primary focus:border-primary ${
            error ? 'border-red-500' : 'border-gray-300'
          }`}
          placeholder="Enter amount"
          {...props}
        />
        <select
          id={currencyId}
          name={currencyName}
          value={currencyValue}
          onChange={onChange}
          className="rounded-r-md border-l-0 border-gray-300 bg-gray-50 px-3 py-2 shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
        >
          {currencyOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
      {helpText && (
        <p className="mt-1 text-sm text-gray-500">{helpText}</p>
      )}
    </div>
  );
};

export default CurrencyInput; 