import React, { useContext, useState, useEffect } from 'react';
import { FormDataContext } from '../PropertyFormWizard';
import TimeRangeInput from 'components/UI/Form/TimeRangeInput';

export default function ScheduleStep() {
  const { formData, updateFormData } = useContext(FormDataContext);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  
  // Validate form when component mounts and when formData changes
  useEffect(() => {
    validateForm();
    
    // Check if validation was triggered from WizardNavigation
    if (formData._validateSchedule) {
      setTouched({ 
        default_cleaning_from: true,
        default_cleaning_until: true 
      });
      // Remove the trigger flag
      updateFormData({ _validateSchedule: undefined });
    }
  }, [formData]);
  
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.default_cleaning_from) {
      newErrors.default_cleaning_from = 'Start time is required';
    }
    
    if (!formData.default_cleaning_until) {
      newErrors.default_cleaning_until = 'End time is required';
    }
    
    if (formData.default_cleaning_from && formData.default_cleaning_until) {
      if (formData.default_cleaning_from >= formData.default_cleaning_until) {
        newErrors.time_range = 'End time must be after start time';
      }
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });
    
    // Mark field as touched
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
  };
  
  return (
    <div className="space-y-4">
      <p className="mb-4">
        Set the default cleaning schedule for this property. This timeframe will be used as the default when creating new cleaning jobs.
      </p>
      
      <TimeRangeInput
        label="Default Cleaning Schedule"
        startId="default_cleaning_from"
        startName="default_cleaning_from"
        startValue={formData.default_cleaning_from}
        endId="default_cleaning_until"
        endName="default_cleaning_until"
        endValue={formData.default_cleaning_until}
        startLabel="Cleaning From"
        endLabel="Cleaning Until"
        onChange={handleInputChange}
        error={errors.time_range}
        required
      />
      
      {touched.default_cleaning_from && errors.default_cleaning_from && (
        <p className="text-sm text-red-600">{errors.default_cleaning_from}</p>
      )}
      
      {touched.default_cleaning_until && errors.default_cleaning_until && (
        <p className="text-sm text-red-600">{errors.default_cleaning_until}</p>
      )}
    </div>
  );
}
