import React, { useContext, useEffect, useState } from 'react';
import { FormDataContext } from '../PropertyFormWizard';
import { fetchUsers } from 'api/usersApi';
import MultiSelect from 'components/UI/Form/MultiSelect';
import { UserContext } from 'context/UserContext';

export default function TeamStep() {
  const { formData, updateFormData } = useContext(FormDataContext);
  const { user } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState({});
  
  useEffect(() => {
    const loadUsers = async () => {
      try {
        const fetchedUsers = await fetchUsers();
        setUsers(fetchedUsers);
      } catch (error) {
        console.error('Failed to fetch users:', error);
        setErrors(prev => ({
          ...prev,
          fetchUsers: 'Failed to load users. Please try again.'
        }));
      }
    };
    
    loadUsers();
  }, []);
  
  const userOptions = users.map(user => ({
    value: user.id,
    label: `${user.first_name} ${user.last_name}`,
  }));
  
  const managerOptions = user ? userOptions.filter(option => 
    option.value !== user.id
  ) : userOptions;
  
  const selectedCleaners = userOptions.filter(option => 
    formData.cleaners.includes(option.value)
  );
  
  const selectedManagers = managerOptions.filter(option => 
    formData.managers.includes(option.value)
  );
  
  const handleCleanersChange = (selectedOptions) => {
    updateFormData({
      cleaners: selectedOptions ? selectedOptions.map(option => option.value) : []
    });
  };
  
  const handleManagersChange = (selectedOptions) => {
    updateFormData({
      managers: selectedOptions ? selectedOptions.map(option => option.value) : []
    });
  };
  
  return (
    <div className="space-y-6">
      {errors.fetchUsers && (
        <div className="p-3 bg-red-100 text-red-700 rounded-md mb-4">
          {errors.fetchUsers}
        </div>
      )}
      
      <p className="text-gray-700 mb-4">
        Assign team members who will be responsible for this property.
      </p>
      
      <MultiSelect
        label="Assign Cleaners"
        id="cleaners"
        name="cleaners"
        options={userOptions}
        value={selectedCleaners}
        onChange={handleCleanersChange}
        helpText="Select cleaners who will be responsible for cleaning this property."
        className="mb-6"
        required
        isMulti={true}
      />
      
      <MultiSelect
        label="Assign Managers"
        id="managers"
        name="managers"
        options={managerOptions}
        value={selectedManagers}
        onChange={handleManagersChange}
        helpText="Select managers who will oversee this property."
        className="mb-4"
        isMulti={true}
      />
    </div>
  );
}
