import React, { useContext, useEffect, useState } from 'react';
import { WizardContext, FormDataContext } from '../PropertyFormWizard';
import ProgressStep from 'components/UI/Progress/ProgressStep';
import ProgressBar from 'components/UI/Progress/ProgressBar';

export default function WizardProgress() {
  const { steps, currentStepIndex, goToStep } = useContext(WizardContext);
  const { formData } = useContext(FormDataContext);
  const [completedSteps, setCompletedSteps] = useState([]);
  
  // Track the highest step index reached
  useEffect(() => {
    // Update completed steps when moving forward
    if (isStepValid(currentStepIndex)) {
      setCompletedSteps(prev => {
        const newCompletedSteps = [...prev];
        if (!newCompletedSteps.includes(currentStepIndex)) {
          newCompletedSteps.push(currentStepIndex);
        }
        return newCompletedSteps.filter(isStepValid);
      });
    }
  }, [currentStepIndex, formData]);
  
  // Function to check if a step is valid based on its requirements
  const isStepValid = (stepIndex) => {
    const step = steps[stepIndex];
    
    if (!step.required) {
      return true; // Optional steps are always valid
    }
    
    switch (step.id) {
      case 'basics':
        return formData.title.trim() && formData.address.trim();
      case 'budget':
        return !!formData.default_job_price;
      case 'schedule':
        return !!formData.default_cleaning_from && !!formData.default_cleaning_until;
      case 'team':
        return formData.managers.length > 0 || formData.cleaners.length > 0;
      default:
        return true; // Other steps don't have specific validation
    }
  };
  
  // Check if a step is completed
  const isStepCompleted = (index) => {
    return completedSteps.includes(index) && isStepValid(index);
  };
  
  // Check if a step is accessible
  const isStepAccessible = (index) => {
    // Allow navigation to any completed step or the current step
    return isStepCompleted(index) || index === currentStepIndex || index < currentStepIndex;
  };
  
  // Calculate progress percentage
  const calculateProgress = () => {
    const maxStep = Math.max(...completedSteps, currentStepIndex);
    return ((maxStep) / (steps.length - 1)) * 100;
  };
  
  return (
    <div className="mb-4">
      <div className="flex justify-between mb-1 overflow-x-auto pb-2 scrollbar-hide">
        {steps.map((step, index) => (
          <ProgressStep
            key={step.id}
            title={step.title}
            number={index + 1}
            isActive={index === currentStepIndex}
            isCompleted={isStepCompleted(index)}
            isAccessible={isStepAccessible(index)}
            onClick={() => goToStep(index)}
            className="mx-1 min-w-[60px] sm:min-w-0"
          />
        ))}
      </div>
      
      <ProgressBar
        progress={calculateProgress()}
        current={currentStepIndex}
        min={0}
        max={steps.length - 1}
      />
    </div>
  );
}
