import React from 'react';

/**
 * FormSelect component for dropdown selection with labels, validation, and help text
 * @param {Object} props - Component props
 * @param {string} props.label - Select label
 * @param {string} props.id - Select ID
 * @param {string} props.name - Select name
 * @param {string} props.value - Selected value
 * @param {Array} props.options - Array of option objects [{value: 'option1', label: 'Option 1'}, ...]
 * @param {Function} props.onChange - Function called when selection changes
 * @param {Function} props.onBlur - Function called when select loses focus
 * @param {string} props.error - Error message
 * @param {string} props.helpText - Help text
 * @param {boolean} props.required - Whether the select is required
 * @param {string} props.className - Additional CSS classes
 */
const FormSelect = ({
  label,
  id,
  name,
  value,
  options = [],
  onChange,
  onBlur,
  error,
  helpText,
  required = false,
  className = '',
  ...props
}) => {
  return (
    <div className={className}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        className={`w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary ${
          error ? 'border-red-500' : 'border-gray-300'
        }`}
        {...props}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
      {helpText && (
        <p className="mt-1 text-sm text-gray-500">{helpText}</p>
      )}
    </div>
  );
};

export default FormSelect;
