import React, { useContext } from 'react';
import { FormDataContext } from '../PropertyFormWizard';
import FormSelect from 'components/UI/Form/FormSelect';
import FormInput from 'components/UI/Form/FormInput';
import TextAreaInput from 'components/UI/Form/TextAreaInput';
import FormCheckbox from 'components/UI/Form/FormCheckbox';
import Alert from 'components/UI/Alert/Alert';

export default function OptionalDetailsStep() {
  const { formData, updateFormData } = useContext(FormDataContext);
  
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    updateFormData({
      [name]: type === 'checkbox' ? checked : value
    });
  };
  
  const sizeOptions = [
    { value: '', label: 'Select size' },
    ...Array.from({ length: 16 }, (_, i) => {
      const size = [5, 10, 15, 20, 25, 30, 40, 50, 65, 80, 100, 120, 140, 160, 180, 200][i];
      return { value: size, label: `${size} m²` };
    })
  ];
  
  const roomOptions = [
    { value: '', label: 'Select' },
    ...Array.from({ length: 21 }, (_, i) => ({ 
      value: i, 
      label: i.toString() 
    }))
  ];
  
  return (
    <div className="space-y-6">
      <Alert variant="info" className="mb-4">
        These details help cleaners understand the scope of work. You can skip this step and add these details later.
      </Alert>
      
      <FormSelect
        label="Square Meters"
        id="square_meters"
        name="square_meters"
        value={formData.square_meters}
        options={sizeOptions}
        onChange={handleInputChange}
        className="mb-4"
      />
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <FormSelect
          label="Number of Rooms"
          id="number_rooms"
          name="number_rooms"
          value={formData.number_rooms}
          options={roomOptions}
          onChange={handleInputChange}
        />
        
        <FormSelect
          label="Number of Bathrooms"
          id="number_bathrooms"
          name="number_bathrooms"
          value={formData.number_bathrooms}
          options={roomOptions}
          onChange={handleInputChange}
        />
      </div>
      
      <FormCheckbox
        id="has_cleaning_supplies"
        name="has_cleaning_supplies"
        checked={formData.has_cleaning_supplies || false}
        onChange={handleInputChange}
        label="Cleaning Supplies Available"
        className="mb-4"
      />
      
      <TextAreaInput
        label="Property Description"
        id="description"
        name="description"
        value={formData.description}
        onChange={handleInputChange}
        rows={4}
        placeholder="Enter any additional information about the property..."
        className="mb-4"
      />
    </div>
  );
}
