import React from "react"
import styles from './JobList.module.css';
import Select from 'react-select';
import JobDetails from '../../../Calendar/JobDetails';
import { UserContext } from '../../../../../context/UserContext';
import getSymbolFromCurrency from 'currency-symbol-map';

export default function JobList({ jobs, userRole, onClose, onJobDeleted, paymentFilter, setPaymentFilter, handleReopenJob }) {


  const [sortField, setSortField] = React.useState("date_of_job");
  const [sortOrder, setSortOrder] = React.useState("asc");

  const columns = [
    { label: "Payment Status", field: "payment" },
    { label: "Property Name", field: "property_title" },
    { label: userRole === "manager" ? "Cleaner" : "Manager", field: userRole === "manager" ? "cleaner_first_name" : "manager_first_name" },
    { label: "Date", field: "date_of_job" },
    { label: "Price", field: "price_cents" },
  ];

  const handleSortChange = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const sortJobs = (jobs) => {
    return [...jobs].sort((a, b) => {
      const valueA = a[sortField];
      const valueB = b[sortField];
      if (valueA === valueB) return 0;
      if (sortOrder === "asc") {
        return valueA < valueB ? -1 : 1;
      } else {
        return valueA > valueB ? -1 : 1;
      }
    });
  };

  const sortedJobs = sortJobs(jobs);


  // State to store the selected filter options for each field
  const [filterOptions, setFilterOptions] = React.useState({});

  // Add payment filter logic
  const paymentFilteredJobs = sortedJobs.filter(job => {
    if (paymentFilter === 'all') return true;
    if (paymentFilter === 'paid') return job.paid === true;
    if (paymentFilter === 'unpaid') return job.paid === false;
    return true;
  });

  // Update the final filtered jobs to include payment filter
  const filteredJobs = paymentFilteredJobs.filter(job => {
    return Object.keys(filterOptions).every(field => {
      const selectedValues = filterOptions[field].map(option => option.value);
      if (field === "price_cents") {
        return selectedValues.includes((job[field] / 100).toFixed(2));
      } else {
        return selectedValues.includes(job[field]);
      }
    });
  });


  const { user } = React.useContext(UserContext);
  const [selectedJob, setSelectedJob] = React.useState(null);
  const [isJobDetailsModalOpen, setIsJobDetailsModalOpen] = React.useState(false);

  const handleJobClick = (job) => {
    setSelectedJob(job.id);
    setIsJobDetailsModalOpen(true);
  };

  const closeJobDetailsModal = (e) => {
    if (!e || e.target.classList.contains(styles.modalOverlay)) {
      setIsJobDetailsModalOpen(false);
      setSelectedJob(null);
    }
  };

  const formatPrice = (cents, currency) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: currency || 'EUR'
    }).format(cents / 100);
  };

  return (
    <div className={styles.cardContainer}>
      {/* Add close button */}
      <button className={styles.closeButton} onClick={onClose}>
        ✕
      </button>
      
      <div className={styles.cardContainer}>
        {/* Add filter button next to the tabs */}
        <div className={styles.headerControls}>
          <div className={styles.tabContainer}>
            <button 
              className={`${styles.tab} ${paymentFilter === 'all' ? styles.activeTab : ''}`}
              onClick={() => setPaymentFilter('all')}
            >
              All
            </button>
            <button 
              className={`${styles.tab} ${paymentFilter === 'paid' ? styles.activeTab : ''}`}
              onClick={() => setPaymentFilter('paid')}
            >
              Paid
            </button>
            <button 
              className={`${styles.tab} ${paymentFilter === 'unpaid' ? styles.activeTab : ''}`}
              onClick={() => setPaymentFilter('unpaid')}
            >
              Not Paid
            </button>
          </div>
        </div>

        {/* Desktop View */}
        <div className={styles.desktopList}>
          <div className={styles.headerRow}>
            {columns.map((col, index) => (
              <div key={index} className={styles.headerCell}>
                {col.label}
              </div>
            ))}
          </div>
          {filteredJobs.map((job, index) => (
            <div key={index} className={styles.jobRow}>
              <div>{job.paid ? 'Paid' : 'Not Paid'}</div>
              <div>{job.property_title}</div>
              <div>{job[userRole === "manager" ? "cleaner_first_name" : "manager_first_name"]}</div>
              <div>{new Date(job.date_of_job).toLocaleDateString()}</div>
              <div>{formatPrice(job.price_cents, job.price_currency)}</div>
              <button className={styles.detailsButton} onClick={() => handleJobClick(job)}>Details</button>
            </div>
          ))}
        </div>

        {/* Mobile View */}
        <div className={styles.mobileList}>
          {filteredJobs.map((job, index) => (
            <div key={index} className={styles.jobCard}>
              <div className={`${styles.jobStatus} ${job.paid ? styles.paid : ''}`}>
                {job.paid ? 'Paid' : 'Not Paid'}
              </div>
              <div className={styles.propertyName}>{job.property_title}</div>
              <div className={styles.jobDetail}>
                <span>Cleaner:</span>
                <span>{job[userRole === "manager" ? "cleaner_first_name" : "manager_first_name"]}</span>
              </div>
              <div className={styles.jobDetail}>
                <span>Date:</span>
                <span>{new Date(job.date_of_job).toLocaleDateString()}</span>
              </div>
              <div className={styles.price}>
                {formatPrice(job.price_cents, job.price_currency)}
              </div>
              <button className={styles.detailsButton} onClick={() => handleJobClick(job)}>Details</button>
            </div>
          ))}
        </div>
      </div>
      {isJobDetailsModalOpen && selectedJob && (
        <div className={styles.modalOverlay} onClick={closeJobDetailsModal}>
          <div className={styles.modalContent}>
            <JobDetails 
              jobId={selectedJob} 
              onClose={() => closeJobDetailsModal()} 
              user={user}
              onJobDeleted={(deletedJobId) => {
                closeJobDetailsModal();
                onJobDeleted(deletedJobId);
              }}
              handleReopenJob={handleReopenJob}
            />
          </div>
        </div>
      )}
    </div>
  );
}
